/* New business landing page start */
.text_light_100 {
    color: #5F5E62 !important;
}

.text_light_200 {
    color: #5D5D5D !important;
}

.new_business_landing h2 {
    max-width: 620px;
    width: 100%;
}

.new_business_landing p {
    max-width: 620px;
    width: 100%;
}

.new_business_landing {
    margin-bottom: 125px;
}

.new_business_landing a {
    padding: 15px 25px;
}

.new_business_unique_edge_card {
    padding-top: 50px;
}

.new_business_unique_edge_card .card {
    box-shadow: 4px 4px 20px 0px #B8C4FF40;
    border-radius: 10px;
    border: 0px;
}

.new_business_unique_edge_card .card .card-body {
    padding: 50px;
}

.new_business_unique_edge_card .card .card-body img {
    width: 219.33px;
    height: 230px !important;
}

.new_business_unique_edge_card .card .card-body h3 {
    font-size: 32px;
    font-weight: 400;
    color: #000C2A;
    margin-bottom: 25px;
}

.new_business_unique_edge_card .card .card-body p {
    font-size: 16px;
    font-weight: 400;
    color: #5F5E62;
}

.new_business_account_quick {
    background-color: #DBE5FF;
    padding: 100px 0px;
}

.new_business_account_quick span {
    font-size: 16px;
    font-weight: 400;
    color: #000C2A;
    padding-bottom: 10px;
}

.new_business_account_quick h3 {
    font-size: 48px;
    font-weight: 400;
    color: #000C2A;
    padding-bottom: 50px;
    max-width: 700px;
    text-align: center;
    margin: auto;
}

.new_business_account_quick p {
    font-size: 16px;
    font-weight: 400;
    color: #5F5E62;
    max-width: 93%;
    margin: auto;
    text-align: center;
    padding-bottom: 50px;
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .new_business_landing h2 {
        font-size: 26px;
    }

    .new_business_unique_edge_card .card .card-body {
        padding: 30px;
    }

    .new_business_landing {
        margin-bottom: 60px;
    }

    .new_business_unique_edge_card .card .card-body h3 {
        font-size: 25px;
        margin-bottom: 15px;
    }

    .new_business_account_quick h3 {
        font-size: 37px;
        padding-bottom: 20px;
    }

    .new_business_account_quick p {
        padding-bottom: 0px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 200px) {
    .new_business_landing h2 {
        font-size: 26px;
    }

    .new_business_unique_edge_card .card .card-body {
        padding: 15px;
    }

    .new_business_landing {
        margin-bottom: 40px;
    }

    .new_business_unique_edge_card .card .card-body h3 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .new_business_unique_edge_card .card .card-body img {
        width: 150px;
        height: auto !important;
    }

    .new_business_unique_edge_card .card .card-body p {
        font-size: 14px;
        margin-bottom: 0px;
    }

    .sm-fs-14 {
        font-size: 14px !important;
    }

    .new_business_unique_edge_card {
        padding-top: 20px;
    }

    .new_business_landing .team-pricing {
        padding-bottom: 2rem;
    }

    .new_business_landing .team-pricing a {
        font-size: 14px !important;
        padding: 12px 12px;
    }

    .new_business_landing .team-pricing .order-2 {
        padding-top: 50px;
    }

    .new_business_account_quick h3 {
        font-size: 25px;
        padding-bottom: 20px;
    }

    .new_business_account_quick p {
        padding-bottom: 0px;
        font-size: 14px;
    }

    .new_business_account_quick button {
        padding: 12px 12px !important;
        font-size: 14px;
    }

    .new_business_account_quick {
        padding: 50px 0px;
    }

}

@media only screen and (max-width: 374px) and (min-width: 0px) {
    .row-cols-2>* {
        width: 1000%;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 1000px) {
    .new_business_landing .team-pricing {
        height: 100vh;
    }
}


@media only screen and (max-width: 2560px) and (min-width: 1200px) {
    .new_business_unique_vector_yellow {
        position: absolute;
        right: 0%;
        top: 13%;
        width: 310px;
        height: auto;
        z-index: -1;
    }

    .new_business_unique_vector_green {
        position: absolute;
        right: 0%;
        bottom: 0%;
        width: 250px;
        height: 500px;
        z-index: -1;
    }

    .new_business_unique_vector_pink {
        position: absolute;
        left: 0%;
        top: 50%;
        width: 200px;
        height: auto;
        z-index: -1;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
    .new_business_unique_vector_yellow {
        position: absolute;
        right: 0%;
        top: 15%;
        width: 243px;
        height: auto;
        z-index: -1;
    }

    .new_business_unique_vector_green {
        position: absolute;
        right: 0%;
        bottom: 0%;
        width: 200px;
        height: 500px;
        z-index: -1;
    }

    .new_business_unique_vector_pink {
        position: absolute;
        left: 0%;
        top: 55%;
        width: 120px;
        height: auto;
        z-index: -1;
    }

    .new_business_landing {
        margin-bottom: 60px;
    }

}

@media only screen and (max-width: 991px) and (min-width: 0px) {

    .new_business_unique_vector_yellow,
    .new_business_unique_vector_green,
    .new_business_unique_vector_pink {
        display: none;
    }
}

/* New business landing page end */
/* New data privacy trail start */
.business_data_privacy_trail .points_header {
    box-shadow: 4px 4px 20px 0px #B8C4FF40;
    border-top: 2px solid #f0f0f0;
    padding: 8px 0px;
}

.business_data_privacy_trail .award_btn {
    background-color: #FFF3DA !important;
    border-radius: 8px;
    padding: 6px 11px;
    font-size: 24px;
    color: #FDC93A !important;
    max-width: auto !important;
    width: auto !important;
}

.business_data_privacy_trail .points_header span {
    font-weight: 400;
    font-size: 13px;
    color: #636168;
}

.tooltip_bottom_text {
    font-weight: 400;
    font-size: 12px;
    color: #FBF8FD;
    margin: 0px;
}

.business_data_privacy_trail .points_header span .badge.text-warning {
    font-weight: 700;
    color: #FDC93A !important;
}

/* .business_data_privacy_trail .points_header span .text-primary {
    font-weight: 700;
    color: #002FA7;
} */

.business_data_privacy_trail .points_header .points_earned {
    font-weight: 700;
    font-size: 12px;
    color: #363f57;
}

.business_data_privacy_trail .trail_progress {
    background-color: #DBE5FF;
    padding: 65px 0px;
}

.business_data_privacy_trail .trail_progress .badge {
    background-color: #DBE5FF;
    font-size: 16px;
    font-weight: 400;
    color: #000C2A;
    padding: 8px 12px;
    background-color: #D2E23D;
    border-radius: 5px;
}

.business_data_privacy_trail .trail_progress p {
    font-size: 16px;
    font-weight: 400;
    color: #5F5E62;
    padding-top: 10px;
    margin-bottom: 25px;
}

.business_data_privacy_trail .trail_progress h3 {
    font-size: 32px;
    font-weight: 400;
    color: #000C2A;
    padding-top: 10px;
    margin-bottom: 34px;
}

.business_data_privacy_trail .trail_progress .link_button,
.business_data_privacy_trail .trail_progress a {
    font-size: 18px;
    font-weight: 600;
    color: #fff !important;
    padding: 16px 32px;
    background-color: #000C2A;
}

.business_data_privacy_trail .trail_progress h4 {
    font-size: 28px;
    font-weight: 400;
    color: #000000;
    padding-top: 63px;
    margin-bottom: 26px;
}

.business_data_privacy_trail .trail_progress h5 {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 16px;
}

.business_data_privacy_trail .trail_progress .progress {
    --bs-progress-bg: #B8CCFF;
    --bs-progress-height: 17px;
    border-radius: 20px;
}

.business_data_privacy_trail .data_privacy_overview a {
    color: #fff !important;
    background-color: #002FA7;
}

.business_data_privacy_trail .data_privacy_overview h2 {
    font-size: 32px;
    font-weight: 400;
    color: #000000;
    margin: 0px;
}

.business_data_privacy_trail .data_privacy_overview .card {
    box-shadow: 4px 4px 20px 0px #B8C4FF40;
    border: 0px;
    border-radius: 8.58px;
}

.business_data_privacy_trail .data_privacy_overview .card .card-body {
    padding: 23px 40px;
}

.business_data_privacy_trail .data_privacy_overview .card h4 {
    font-size: 24px;
    font-weight: 700;
    color: #000C2A;
}

.business_data_privacy_trail .data_privacy_overview .card p {
    font-size: 16px;
    font-weight: 400;
    color: #5F5E62;
    max-width: 900px;
}

@media only screen and (max-width: 1023px) and (min-width: 0px) {
    .business_data_privacy_trail .trail_progress p {
        margin-bottom: 0px;
    }

    .business_data_privacy_trail .trail_progress h3 {
        margin-bottom: 10px;
        font-size: 22px;
    }

    .business_data_privacy_trail .trail_progress h4 {
        padding-top: 15px;
        margin-bottom: 10px;
        font-size: 20px;
    }

    .business_data_privacy_trail .trail_progress h5 {
        font-size: 16px;
    }

    .business_data_privacy_trail .trail_progress a {
        font-weight: 400;
        padding: 10px 13px;
        font-size: 14px;
    }

    .business_data_privacy_trail .trail_progress {
        padding: 30px 0px;
    }

    .business_data_privacy_trail .data_privacy_overview h2 {
        font-size: 25px;
    }

    .business_data_privacy_trail .data_privacy_overview a {
        padding: 10px 15px;
    }

    .business_data_privacy_trail .data_privacy_overview .card .card-body {
        padding: 20px 20px;
    }

    .business_data_privacy_trail .data_privacy_overview .card h4 {
        font-size: 20px;
    }

    .business_data_privacy_trail .award_btn {
        font-size: 16px;
    }
}

/* New data privacy trail end */
/* New business points start */
.business_points .your_points {
    background-color: #DBE5FF;
    padding: 100px 0px;
}

.business_points .your_points h6 {
    font-size: 24px;
    font-weight: 600;
    color: #000C2A;
    padding-bottom: 10px;
}

.business_points .your_points h5 {
    font-size: 62.32px;
    font-weight: 700;
    color: #000C2A;
    padding-bottom: 15px;
}

.business_points .your_points p {
    font-size: 18px;
    font-weight: 400;
    color: #5F5E62;
    margin-bottom: 0px;
}

.business_points .your_points p span {
    font-weight: 700;
    color: #000C2A;
}

.business_points .points_history {
    padding-bottom: 50px;
}

.business_points .points_history .card {
    border: 0px;
    box-shadow: 4px 4px 10px 0px #B8C4FF0D;
    border-radius: 10px;
}

.business_points .points_history {
    margin-top: -60px;
}

.business_points .points_history h3 {
    font-size: 24px;
    font-weight: 600;
    color: #191C32;
    padding-bottom: 15px;
}

.business_points .points_history table thead th {
    font-size: 14px;
    font-weight: 700;
    color: #333333;
    padding-bottom: 15px;
}

.business_points .points_history table tbody td {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    padding-bottom: 15px;
}

.business_points .points_history table tbody tr td:nth-child(3),
.business_points .points_history table tbody tr td:nth-child(4) {
    font-size: 24px;
    font-weight: 500;
}


/* New business points end */
/* New business public spaces start */
.business_case_study .left_side_images {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #002FA7;
    padding: 100px 20px;
}

.business_public_spaces .right_side_information {
    max-width: 700px;
    width: 100%;
    padding-left: 60px;
    padding-top: 40px;
}

.business_public_spaces .right_side_information span {
    font-size: 18px;
    font-weight: 400;
    color: #002FA7;
    padding-bottom: 30px;
    display: inline-flex;
}

.business_public_spaces .right_side_information h4 {
    font-size: 36px;
    font-weight: 600;
    color: #000C2A;
    padding-bottom: 40px;
    max-width: 600px;
    width: 100%;
}

.business_public_spaces .right_side_information h5 {
    font-size: 32px;
    font-weight: 500;
    color: #000C2A;
    padding-bottom: 19px;
}

.business_public_spaces .right_side_information p {
    font-size: 16px;
    font-weight: 400;
    color: #5F5E62;
    padding-bottom: 80px;
}

.business_public_spaces .right_side_information button:nth-child(1),
.business_public_spaces .right_side_information a:nth-child(1) {
    background-color: #DBE5FF;
    padding: 16px 28px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    color: #000C2A;
    width: 100%;
    border: 0px;
}

.business_public_spaces .right_side_information button:nth-child(2),
.business_public_spaces .right_side_information a:nth-child(2) {
    background-color: #000C2A;
    padding: 16px 28px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    width: 100%;
    border: 0px;
}

.business_public_spaces .right_side_information .progressbar_custom {
    width: 250px;
    padding-top: 40px;
    padding-top: 30px;
    margin: auto;
}

.business_public_spaces .right_side_information .progressbar_custom .progress {
    height: 8px;
    background-color: #B8CCFF;
}

.business_public_spaces .right_side_information .progressbar_custom .progress-bar {
    border-radius: 8px;
}

.business_public_spaces .right_side_information .progressbar_custom h6 {
    color: #002FA7;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

@media only screen and (max-width: 3000px) and (min-width: 1400px) {
    .business_case_study .left_side_images {
        height: 100vh;
    }
}

@media only screen and (max-width: 1399px) and (min-width: 1024px) {
    .business_case_study .left_side_images {
        height: calc(100vh + 200px);

    }
}

@media only screen and (max-width: 1023px) and (min-width: 0px) {
    .business_case_study .left_side_images {
        height: 100%;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 0px) {
    .business_public_spaces .right_side_information {
        padding-left: 15px;
    }

    .business_public_spaces .right_side_information p {
        font-size: 14px;
        padding-bottom: 20px;
    }

    .business_public_spaces .right_side_information h4 {
        font-size: 25px;
        padding-bottom: 10px;
    }

    .business_public_spaces .right_side_information h5 {
        font-size: 20px;
        padding-bottom: 5px;
    }

    .business_public_spaces .right_side_information span {
        font-size: 16px;
        padding-bottom: 15px;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .business_case_study .left_side_images {
        padding: 80px 20px;
    }
}


@media only screen and (max-width: 767px) and (min-width: 200px) {
    .business_case_study .left_side_images {
        padding: 40px 20px;
    }

    .business_public_spaces .right_side_information {
        padding-top: 20px;
    }

    .w-sm-100 {
        width: auto !important;
    }

    .business_public_spaces .right_side_information a:nth-child(1),
    .business_public_spaces .right_side_information a:nth-child(2),
    .business_public_spaces .right_side_information button:nth-child(1),
    .business_public_spaces .right_side_information button:nth-child(2) {
        padding: 12px 10px;
        font-size: 14px;
    }

    .business_public_spaces .right_side_information {
        padding-left: 0px;
    }

    .business_public_spaces .right_side_information h4 {
        font-size: 20px;
        padding-bottom: 4px;
    }

    .business_public_spaces .right_side_information span {
        font-size: 14px;
        padding-bottom: 8px;
    }

    .business_public_spaces .right_side_information .progressbar_custom {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

/* New business public spaces end */
/* New business public spaces question start */
.business_public_question .question_list label {
    border: 1px solid #B8CCFF;
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 30px;
}

.business_public_question .question_list label p {
    font-size: 18px;
    font-weight: 400;
    color: #000C2A;
}

/* Selected */
.business_public_question .question_list label.selected {
    background-color: #EDF2FF;
    border-color: #00237D;
}

.business_public_question .question_list label.selected p {
    color: #00237D;
}

.business_public_question .selected .form-check-input:checked {
    background-color: transparent;
    border-color: #002FA7;
}

.business_public_question .selected .form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url("../assets/images/check.webp") !important;
}

/* wrong */
.business_public_question .question_list label.wrong {
    background-color: #FCE8EE;
    border-color: #DB0544;
}

.business_public_question .question_list label.wrong p {
    color: #DB0544;
}

.business_public_question .wrong .form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url("../assets/images/close.webp") !important;
}

.business_public_question .wrong .form-check-input:checked {
    background-color: transparent;
    border-color: #DB0544;
}

/* right */
.business_public_question .question_list label.right {
    background-color: #ECFAEF;
    border-color: #2EC04F;
}

.business_public_question .question_list label.right p {
    color: #2EC04F;
}

.business_public_question .right .form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url("../assets/images/right.webp") !important;
}

.business_public_question .right .form-check-input:checked {
    background-color: transparent;
    border-color: #2EC04F;
    --bs-form-check-bg-image: url("../assets/images/right.webp") !important;
}

.business_public_question .question_list label input {
    width: 25px;
    height: 25px;
    background-color: transparent;
    border-radius: 50% !important;
}

.business_public_question .form-check-input {
    background-size: 15px;
}

.business_public_question .right_side_information h2 {
    font-size: 16px;
    font-weight: 600;
    color: #000C2A;
    text-align: center;
    padding-top: 30px;
}


.optional_feedback button {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 500;
    color: #6682ca;
    border: 1px solid #6682ca;
    border-radius: 8px;
    background-color: #FFFFFF;
}

.optional_feedback button {
    width: 130px;
}

.optional_feedback button.active {
    border-color: #002FA7;
    color: #002FA7;
}

.congratulations_modal_custom .modal-content {
    background-color: #002FA7;
    max-width: 644px;
    margin: auto;
    padding: 28px;
    border-radius: 30px;
}


.congratulations_modal_custom h3 {
    font-size: 24px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 16px;
    margin-top: -40px;
}

.congratulations_modal_custom p {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    max-width: 300px;
    margin: auto;
    margin-bottom: 20px;
}

.congratulations_modal_custom a,
.congratulations_modal_custom button {
    font-size: 18px;
    font-weight: 600;
    color: #000C2A;
    background-color: #FDC93A;
    max-width: 230px;
    width: 100%;
    margin: auto;
    border: 0px;
    padding: 14px;
    border-radius: 8px;
}

@media only screen and (max-width: 767px) and (min-width: 200px) {
    .congratulations_modal_custom img {
        width: 250px;
    }

    .congratulations_modal_custom h3 {
        font-size: 18px;
    }

    .congratulations_modal_custom p {
        font-size: 14px;
    }

    .congratulations_modal_custom button {
        font-size: 16px;
        max-width: 150px;
        padding: 10px;
    }

    .business_public_question .right_side_information h2 {
        padding-top: 5px;
    }
}

/* New business public spaces question end */
/* New business case studies results start */

.business_results .business_results_right {
    max-width: 700px;
    width: 100%;
    padding-left: 60px;
    padding-top: 40px;
}

.business_results .business_results_right h4 {
    font-size: 100px;
    font-weight: 600;
    color: #000C2A;
}

.business_results .business_results_right h5 {
    font-size: 40px;
    font-weight: 600;
    color: #000C2A;
}

.business_results .business_results_right h6 {
    font-size: 32px;
    font-weight: 400;
    color: #000C2A;
    margin-bottom: 20px;
}

.business_results .business_results_right p {
    font-size: 16px;
    font-weight: 400;
    color: #5F5E62;
    margin-bottom: 16px;
}

.business_results .points_earned {
    font-size: 20px;
    font-weight: 600;
    color: #000C2A;
}

.business_results .still_questions span {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.business_results .still_questions a {
    color: #002FA7;
}

.business_results .trail_progressbar h2 {
    font-size: 28px;
    font-weight: 700;
    color: #000;
}

.business_results .trail_progressbar span {
    font-size: 20px;
    font-weight: 400;
    color: #000C2A;
}

.business_results .trail_progressbar .progress {
    background-color: #B8CCFF;
    border-radius: 8px;
}

.business_results .trail_progressbar .progress-bar {
    border-radius: 8px;
}

.business_results .next_steps_title p {
    font-size: 20px;
    font-weight: 400;
    color: #000;
}

.business_results .course_cards h6 {
    font-size: 24px;
    font-weight: 700;
    color: #000C2A;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) and (min-width: 0px) {
    .business_results .business_results_right {
        padding-left: 15px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 0px) {
    .business_results .business_results_right {
        padding-left: 0px;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 0px) {
    .business_results .business_results_right h4 {
        font-size: 50px;
    }

    .business_results .business_results_right h5 {
        font-size: 25px;
    }

    .business_results .business_results_right h6 {
        font-size: 20px;
        font-weight: 400;
        color: #000C2A;
        margin-bottom: 20px;
    }

    .business_results .business_results_right p {
        font-size: 14px;
    }

    .business_public_spaces .right_side_information button:nth-child(2),
    .business_public_spaces .right_side_information a:nth-child(2),
    .business_public_spaces .right_side_information button:nth-child(1),
    .business_public_spaces .right_side_information a:nth-child(1) {
        padding: 10px 10px;
        font-size: 14px;
    }

    .business_results .trail_progressbar h2 {
        font-size: 20px;
    }

    .business_results .trail_progressbar span {
        font-size: 16px;
    }
}

/* New business case studies results end */
/* New business Puzzle start */

.business_puzzle .puzzle_top_box {
    background-color: #002FA7;
    border-radius: 30px;
    padding: 35px 45px 70px;
    margin-top: 50px;
}

.business_puzzle .puzzle_top_box span {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 12px;
    display: block;
}

.business_puzzle .puzzle_top_box h2 {
    font-size: 36px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 14px;
}

.business_puzzle .puzzle_top_box p {
    font-size: 16px;
    font-weight: 400;
    color: #E2E2E2;
    margin-bottom: 0px;
}

.business_puzzle .drag_drop_cards .drag_drop_col_1 .card {
    box-shadow: 4px 4px 20px 0px #B8C4FF40;
    border-color: #E6E6E6;
    border-radius: 10px;
    margin-bottom: 20px;
}

.business_puzzle .drag_drop_cards .drag_drop_col_1 .card .card-body {
    padding: 30px;
}

.business_puzzle .drag_drop_cards .drag_drop_col_1 .card .card-body p {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px
}

.drag_drop_cards {
    box-shadow: 0px 4px 4px 0px #FBECFF;
    border: 0px;
    border-radius: 20px;
    margin-top: -110px;
}

.drag_drop_cards .card-body {
    padding: 25px;
}


.business_puzzle .drag_drop_cards .drag_drop_col_2 {
    background-color: #EFF1FF;
    border: 0px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.business_puzzle .drag_drop_cards .drag_drop_col_2.card-body p {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0px
}


.business_puzzle .drag_drop_cards .drag_drop_col_3 .card-body {
    background-color: #F4F4F4;
    border: 1px dashed #CFCFCF;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
}

.business_puzzle .drag_drop_cards .drag_drop_col_3 .card-body p {
    color: #AFAFAF;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    text-align: center;
}

/* .drag_drop_col_3 {
    border: 2px dashed #ccc;
    padding: 10px;
  }
   */

.card[draggable="true"]:active {
    opacity: 1;
}

.business_puzzle .check_answers_btn button,
.business_puzzle .check_answers_btn a {
    background-color: #002FA7;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    padding: 16px 35px;
    border: 0px;
    border-radius: 8px;
    gap: 35px;
    margin-bottom: 60px;
}

.business_puzzle .trail_progressbar h2 {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
}

.business_puzzle .trail_progressbar span {
    font-size: 20px;
    font-weight: 400;
    color: #000C2A;
}

.business_puzzle .trail_progressbar .progress {
    background-color: #B8CCFF;
}

.business_puzzle .trail_progressbar .progress-bar {
    border-radius: 8px;
}

.business_puzzle .next_steps_title {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}

.business_puzzle .course_cards .card {
    box-shadow: 4px 4px 20px 0px #B8C4FF40;
    border-radius: 10px;
    border: 0px;
}

.business_puzzle .course_cards .card h4 {
    font-size: 24px;
    font-weight: 700;
    color: #000C2A;
}

.business_puzzle .course_cards .card p {
    font-size: 16px;
    font-weight: 400;
    color: #5F5E62;
}

/* New business Puzzle end */
/* New business Quiz - Choose your guide start */
.business_choose_quiz .puzzle_top_box {
    padding-bottom: 150px;
}

.business_choose_quiz .choose_quiz_user_image p {
    color: #000C2A;
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0px 0px 0px;
    text-align: center;
}


.business_choose_quiz .choose_quiz_user_image_circle {
    width: 293px;
    height: 293px;
    border-radius: 50%;
    overflow: hidden;
}

.business_choose_quiz .choose_quiz_user_image_circle.active {
    border: 3px solid #002FA7;
}

.business_choose_quiz .choose_quiz_user_box .card {
    box-shadow: 0px 4px 4px 0px #FBECFF;
    border-radius: 20px;
    border: 0px;
    padding: 55px;
    margin-top: -140px;
}

.business_choose_quiz a,
.business_choose_quiz button {
    background-color: #DBE5FF;
    color: #000C2A;
    font-size: 18px;
    font-weight: 600;
    border-radius: 8px;
    max-width: 230px;
    width: 100%;
    padding: 16px;
    text-align: center;
}

.business_choose_quiz a:nth-child(2),
.business_choose_quiz button:nth-child(2) {
    background-color: #002FA7;
    color: #fff;
}

.business_choose_quiz .rules_box p {
    font-size: 18px;
    font-weight: 400;
    color: #363636;
    padding: 0px 20px;
}

/* New business Quiz - Choose your guide end */
/* New business Quiz start */
.business_quiz_start_home {
    background-image: url("../assets/images/quiz_img.webp") !important;
    height: 84vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quiz_start_btn {
    background-color: #000C2A !important;
    transform: translate(-50%, -50%) !important;
    top: 85%;
    left: 50%;
    position: absolute;
    max-width: 330px !important;
}

.business_quiz_start .business_choose_quiz .choose_quiz_user_image_circle {
    width: 293px;
    height: 293px;
    border-radius: 50%;
    overflow: hidden;
    border: none;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.business_quiz_start .ada_btn {
    background-color: #002FA7 !important;
    max-width: 200px !important;
    color: #fff;
    border-radius: 10px;
    margin-top: -50px;
}

.business_quiz_start .ommo_btn {
    background-color: #FFCA39 !important;
    max-width: 200px !important;
    color: #000C2A;
    border-radius: 10px;
    margin-top: -30px;
}

.start_quiz_modal .modal-content {
    background-color: transparent;
    border: 0px;
}

.quiz_start_btn {
    font-size: 18px;
    padding: 10px 20px;
    background-color: #0D3BAE;
    color: #fff;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.quiz_start_btn:active {
    transform: scale(0.95);
}

.start_quiz_modal .start_quiz_modal_text {
    background-color: #FFFFFF;
    width: 340px;
    height: 202px;
    border-radius: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: 700;
    color: #0D3BAE;
    opacity: 0;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
}

.start_quiz_modal .start_quiz_modal_text:nth-child(1) {
    animation-name: fadeInScaleUp;
    animation-delay: 0s;
}

.start_quiz_modal .start_quiz_modal_text:nth-child(2) {
    animation-name: fadeInScaleUp;
    animation-delay: 2s;
}

@keyframes fadeInScaleUp {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }

    50% {
        opacity: 1;
        transform: scale(1.1);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0.8);
    }
}


/* New business Quiz start end */
/* New business Quiz start */
.business_quiz_main .bg_quiz_box {
    background-image: url("../assets/images/bg-quiz.webp") !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 250px;
    border-radius: 30px;
    margin-top: 50px;
    margin-bottom: -80px;
}

.business_quiz_main .card {
    box-shadow: 0px 11px 4px 0px #F3F3F3;
    border: 0px;
    border-radius: 20px;
    padding: 30px;
}

.business_quiz_main .quiz_user h6 {
    font-size: 14px;
    font-weight: 600;
    color: #002FA7;
    margin-bottom: 10px;
}

.business_quiz_main .quiz_user .choose_quiz_user_image_circle {
    width: 100px;
    height: 100px;
    background-color: #DBE5FF;
    border-radius: 50%;
    overflow: hidden;
}

.business_quiz_main .quiz_user .ada_btn {
    background-color: #002FA7;
    font-size: 12px;
    font-weight: 600;
    padding: 8px;
    color: #fff;
    border-radius: 5px;
    max-width: 100px;
    width: 100%;
    margin-top: -10px;
    margin-bottom: 14px;
}

.business_quiz_main .quiz_user p {
    font-size: 14px;
    font-weight: 600;
    color: #000C2A;
    margin-bottom: 10px;
}

.points_line {
    max-width: 85px;
    width: 100%;
}

.points_line div {
    background-color: #D9D9D9;
    min-width: 12px;
    height: 12px;
    border-radius: 50%;
    overflow: hidden;
}

.business_quiz_main .points_line .right_line {
    background-color: #2D8C42;
}

.business_quiz_main .points_line .wrong_line {
    background-color: #D05A04;
}

.business_quiz_main .question_timer_mian {
    max-width: 300px;
    width: 100%;
    margin-top: -70px !important;
}

.business_quiz_main .question_timer_mian .point_right {
    width: 30px;
    height: 8px;
    background-color: #1F8435;
    border-radius: 15px;
}

.business_quiz_main .question_timer_mian .point_wrong {
    width: 30px;
    height: 8px;
    background-color: #D05A04;
    border-radius: 15px;
}

.business_quiz_main .question_timer_mian .point_right_text {
    color: #1F8435;
    font-size: 14px;
    font-weight: 600;
}

.business_quiz_main .question_timer_mian .point_wrong_text {
    color: #D05A04;
    font-size: 14px;
    font-weight: 600;
}

.business_quiz_main .question_timer_mian .question_counter p {
    color: #002FA7;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 0px;
}

.business_quiz_main .question_timer_mian .countdown_timer {
    width: 90px;
    height: 90px;
    position: relative;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.question {
    font-size: 16px;
    font-weight: 600;
    color: #2B262D;
    text-align: center;
    margin-top: 27px;
    margin-bottom: 35px;
}

.question_point {
    color: #B2B2B2;
    font-weight: 500;
}

.business_quiz_main .use_joker_box {
    margin-top: 50px;
}

.business_quiz_main .use_joker_box span {
    color: #002FA7;
    font-size: 16px;
    font-weight: 600;
}

.business_quiz_main .use_joker_box p {
    color: #686E7E;
    font-size: 14px;
    font-weight: 400;
}

.business_quiz_main .use_joker_box button {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    background-color: #002FA7;
    border-radius: 8px;
    border: 0px;
    padding: 14px 20px;
    max-width: 174px;
    width: 100%;
}

/* .fadeIn_scale_up_animation {
    opacity: 0;
    animation-duration: 3.5s;
    animation-timing-function: ease-in-out;
    animation-name: fadeInScaleUp;
    animation-delay: 0s;
} */

.finish-line-cuate {
    margin-left: -15px !important;
}

.start_quiz_modal .correct_box {
    width: 426px;
    height: auto;
    border-radius: 30px;
    background-color: #fff;
    padding: 50px;
}

.start_quiz_modal .correct_box h3 {
    font-size: 36px;
    font-weight: 600;
    color: #53BE5E;
    margin-top: 30px;
    margin-bottom: 13px;
}

.start_quiz_modal .incorrect_box h3 {
    color: #F7524B;
}

.start_quiz_modal .correct_box p {
    font-size: 24px;
    font-weight: 600;
    color: #555555;
    margin-bottom: 0px;
}

/* New business Quiz end */
/* New business Quiz Results start */
.business_quiz_results .quiz_results_bg {
    background-color: #002FA7;
    border-radius: 30px;
    margin-top: 50px;
    margin-bottom: -80px;
}

.business_quiz_results .business_results_right {
    max-width: 100%;
}

.business_quiz_results .certificate_text {
    padding-top: 70px;
}

.business_quiz_results .certificate_text p {
    font-size: 14px;
    font-weight: 400;
    color: #002FA7;
    margin-bottom: 30px;
}

.business_quiz_results .certificate_text h4 {
    font-size: 32px;
    font-weight: 400;
    color: #000C2A;
    max-width: 550px;
    width: 100%;
    margin: auto;
    margin-bottom: 30px;
}

.business_quiz_results .certificate_text h5 {
    font-size: 24px;
    font-weight: 400;
    color: #000C2A;
    margin-bottom: 30px;
}

.business_quiz_results .certificate_text h6 {
    font-size: 16px;
    font-weight: 400;
    color: #000C2A;
    margin: auto;
    margin-bottom: 30px;
    max-width: 700px;
    width: 100%;
}

.business_quiz_results .certificate_text a,
.business_quiz_results .certificate_text button {
    font-size: 16px;
    font-weight: 600;
    color: #000C2A;
    margin: auto;
    background-color: #D2E23D;
    padding: 16px 32px;
    border-radius: 8px;
    width: 155px;
}

.business_quiz_results .business_results {
    padding: 80px 0px;
}

/* New business Quiz Results end */
/* New business data privacy trail results start */
.percentage_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    font-weight: 700;
}

.business_data_privacy_trail .trail_progress .score_progress_bar h4 {
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 30px;
}

.business_data_privacy_trail .trail_progress .score_progress_bar p {
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 22px;
    min-width: 200px;
}

.business_data_privacy_trail .trail_progress .score_progress_bar p {
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 22px;
    min-width: 200px;
}

.business_data_privacy_trail.score_progress_bar button {
    background-color: #FDC93A;
    color: #FFFFFF;
}

.feedback_modal h3 {
    color: #000C2A;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 16px;
}

.feedback_modal h6 {
    color: #000C2A;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 12px;
}

.feedback_modal button {
    color: #fff !important;
    font-weight: 400;
    font-size: 16px;
}

.feedback_modal .modal-body {
    padding: 30px;
}

.feedback_modal .modal-footer {
    padding: 0px 30px 30px 30px;
}

.feedback_modal img {
    margin-bottom: 16px;
}

.feedback_modal h4 {
    font-size: 20px;
    font-weight: 600;
    color: #000C2A;
    margin-bottom: 20px;
}

.feedback_modal h5 {
    font-size: 16px;
    font-weight: 400;
    color: #000C2A;
    margin-bottom: 20px;
}

/* New business data privacy trail results end */
/* New business Unanswered uestions start */
.business_unanswered_questions .questions_boxs {
    padding: 50px 0px;
}

.business_unanswered_questions h3 {
    font-size: 32px;
    font-weight: 400;
    color: #000C2A;
    margin-bottom: 25px;
}

.business_unanswered_questions p {
    font-size: 16px;
    font-weight: 400;
    color: #5F5E62;
    margin-bottom: 55px;
}

.business_unanswered_questions label {
    font-size: 16px;
    font-weight: 400;
    color: #000C2A;
    margin-bottom: 12px;
}

.business_unanswered_questions .questions_boxs button {
    padding: 10px 30px;
    border: 0px;
}

.business_unanswered_questions .questions_boxs_btn_1 button:nth-child(2) {
    color: #fff !important;
}

.business_unanswered_questions .questions_boxs_btn_2 button:nth-child(1) {
    color: #fff !important;
}

.business_unanswered_questions .questions_boxs_btn_2 textarea,
.business_unanswered_questions .questions_boxs_btn_2 .form-control:focus {
    border-color: #002FA7 !important;
}

/* New business Unanswered uestions end */
/* New business course results start */
.business_course_results .replay {
    background-color: #DBE5FF;
    padding: 16px 28px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    color: #000C2A;
    border: 0px;
    width: 100%;
    max-width: 250px;
}

.business_course_results .start_courses_btn {
    background-color: #000C2A;
    padding: 16px 28px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    border: 0px;
    width: 100%;
    max-width: 250px;
}

.business_course_results section {
    padding-top: 2rem;
}

.business_course_results .badge.badge-primary {
    background-color: #000C2A;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 8px;
    border-radius: 5px;
    margin-bottom: 25px;
}

.business_course_results h3 {
    font-size: 32px;
    font-weight: 400;
    color: #000C2A;
    margin-bottom: 64px;
    text-align: center;
}

.business_course_results h6 {
    font-size: 24px;
    font-weight: 600;
    color: #000C2A;
    margin-bottom: 12px;
    text-align: center;
}

.business_course_results h2 {
    font-size: 56px;
    font-weight: 600;
    color: #000C2A;
    margin-bottom: 12px;
    text-align: center;
}

/* New business course results end */


@media only screen and (max-width: 1199px) and (min-width: 768px) {
    .drag_drop_cards .card-body {
        padding: 20px;
    }

    .drag_drop_cards {
        margin-top: -90px;
    }

    .business_choose_quiz .rules_box p {
        padding: 0px 0px;
    }

    .choose_quiz_user_main_box {
        gap: 100px;
    }

    .finish-line-cuate {
        width: 60%;
        margin-left: 56px !important;
    }

    .start_quiz_modal .correct_box {
        border-radius: 20px;
        padding: 20px;
    }

    .success_check {
        width: 40px;
    }

    .start_quiz_modal .correct_box h3 {
        font-size: 30px;
        margin-top: 10px;
        margin-bottom: 6px;
    }

    .start_quiz_modal .correct_box p {
        margin-bottom: 0px;
    }

    .start_quiz_modal .correct_box p {
        font-size: 20px;
    }

    .business_public_spaces .right_side_information {
        max-width: 400px;
    }

}


@media only screen and (max-width: 991px) and (min-width: 200px) {
    .drag_drop_cards {
        box-shadow: 0px 4px 4px 0px #FBECFF;
        border: 0px;
        border-radius: 20px;
        margin-top: 0px;
    }

    .business_puzzle .puzzle_top_box h2 {
        font-size: 25px;
        margin-bottom: 10px;
    }

    .business_puzzle .puzzle_top_box span {
        font-size: 14px;
    }

    .business_puzzle .puzzle_top_box p {
        font-size: 14px;
    }

    .business_puzzle .puzzle_top_box {
        padding: 30px 30px;
        margin-top: 30px;
    }

    .business_choose_quiz .choose_quiz_user_box .card {
        padding: 15px;
        margin-top: 0px;
    }

    .business_choose_quiz .choose_quiz_user_image_circle {
        width: 250px;
        height: 250px;
    }

    .business_choose_quiz .choose_quiz_user_image_circle img {
        width: 50%;
    }

    .business_quiz_start .business_choose_quiz .choose_quiz_user_image_circle {
        width: 200px;
        height: 200px;
    }

    .business_quiz_start_home {
        height: 55vh;
    }

    .business_choose_quiz a,
    .business_choose_quiz button {
        max-width: 230px !important;
        font-size: 16px;
    }

    .business_quiz_start .ada_btn,
    .business_quiz_start .ommo_btn {
        max-width: 150px !important;
        margin-top: -30px;
        font-size: 15px;
        padding: 12px;
    }

    .business_public_question .question_list label {
        padding: 5px 10px;
    }

    .business_quiz_main .bg_quiz_box {
        height: 160px;
        border-radius: 20px;
        margin-top: 40px;
    }

    .incorrect_img {
        width: 40%;
    }

    .business_data_privacy_trail .trail_progress .score_progress_bar p {
        font-size: 16px;
        min-width: 100%;
    }

    .business_data_privacy_trail .trail_progress .score_progress_bar h4 {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .overall_score_progress .progress_bar_main,
    .overall_score_progress svg {
        width: 100px !important;
        height: 100px !important;
    }

    .overall_score_progress .percentage_text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px !important;
        font-weight: 700;
    }

    .business_course_results h2 {
        font-size: 30px;
        margin-bottom: 0px;
    }

    .business_course_results h6 {
        font-size: 20px !important;
    }

    .business_course_results h3 {
        font-size: 23px;
        margin-bottom: 30px;
    }

    .business_course_results .start_courses_btn,
    .business_course_results .replay {
        font-size: 15px;
    }

    .business_results .business_results_right {
        padding-top: 18px;
    }

    .business_results .points_earned {
        font-size: 16px;
    }
}


@media only screen and (max-width: 767px) and (min-width: 200px) {
    .drag_drop_cards .card-body {
        padding: 10px;
    }

    .business_puzzle .check_answers_btn button {
        background-color: #002FA7;
        font-size: 14px;
        margin-bottom: 25px;
    }

    .drag_drop_col_1.none {
        display: none !important;
    }

    .business_puzzle .drag_drop_cards .drag_drop_col_2 {
        margin-bottom: 5px;
    }

    .click_here {
        padding: 20px;
        background-color: rgb(244, 244, 244);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .drag_drop_col_1 .active {
        background-color: #DBE5FF;
    }

    .drag_drop_col_1 .active p {
        color: #002FA7 !important;
    }

    .drag_drop_col_1 .card:last-child {
        margin-bottom: 0px !important;
    }

    .business_puzzle .trail_progressbar h2 {
        font-size: 18px;
    }

    .business_puzzle .trail_progressbar span {
        font-size: 16px;
        font-weight: 600;
    }

    .business_puzzle .course_cards .card h4 {
        font-size: 20px;
        font-weight: 600;
    }

    .business_choose_quiz .choose_quiz_user_image_circle {
        width: 130px;
        height: 130px;
    }

    .business_choose_quiz .choose_quiz_user_image_circle img {
        width: 50%;
    }

    .business_choose_quiz .choose_quiz_user_image p {
        font-size: 16px;
    }

    .business_choose_quiz a,
    .business_choose_quiz button {
        font-size: 16px;
    }

    .business_quiz_start_home {
        height: 35vh;
    }

    .business_quiz_start .business_choose_quiz .choose_quiz_user_image_circle {
        width: 100px;
        height: 100px;
    }

    .business_choose_quiz a,
    .business_choose_quiz button {
        max-width: 100px !important;
        font-size: 14px !important;
        padding: 12px !important;
    }

    .choose_quiz_user_main_box {
        margin-bottom: 40px;
    }

    .business_quiz_start .business_choose_quiz .choose_quiz_user_image_circle img {
        width: 60%;
    }

    .start_quiz_modal .start_quiz_modal_text {
        font-size: 25px;
        height: auto;
        padding: 15px;
        border-radius: 10px;
    }

    .business_quiz_main .question_timer_mian {
        margin-top: 25px !important;
    }

    .business_quiz_main .card {
        padding: 20px;
    }

    .countdown_timer {
        stroke: #DBE5FF;
    }

    .business_quiz_main .use_joker_box button {
        font-size: 14px;
        max-width: 100px;
        font-weight: 500;
        width: 100%;
    }

    .finish-line-cuate {
        width: 60%;
        margin-left: 56px !important;
    }

    .start_quiz_modal .correct_box {
        width: 100%;
        height: auto;
        border-radius: 10px;
        padding: 20px;
    }

    .success_check {
        width: 40px;
    }

    .start_quiz_modal .correct_box h3 {
        font-size: 25px;
        margin-top: 10px;
        margin-bottom: 6px;
    }

    .start_quiz_modal .correct_box p {
        margin-bottom: 0px;
        font-size: 16px;
    }

    .business_quiz_results .certificate_text {
        padding-top: 30px;
    }

    .business_quiz_results .certificate_text h4 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .business_quiz_results .certificate_text h5 {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .business_quiz_results .certificate_text h6 {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .business_quiz_results .certificate_text a,
    .business_quiz_results .certificate_text button {
        font-size: 14px;
        padding: 13px 25px;
        margin-bottom: 15px;
    }

    .business_unanswered_questions .questions_boxs {
        padding: 30px 0px;
    }

    .business_unanswered_questions h3 {
        font-size: 25px;
        margin-bottom: 10px;
    }

    .business_unanswered_questions p {
        margin-bottom: 35px;
    }

    .feedback_modal .modal-footer {
        padding: 0px 15px 15px 15px;
    }

    .feedback_modal .modal-body {
        padding: 15px;
    }

    .business_points .points_history table tbody tr td:nth-child(3),
    .business_points .points_history table tbody tr td:nth-child(4) {
        font-size: 18px;
    }

    .business_points .points_history table tbody td {
        font-size: 14px;
        min-width: 150px;
    }

    .business_points .your_points h5 {
        font-size: 35px;
        padding-bottom: 10px;
    }

    .business_points .your_points p {
        font-size: 16px;
        padding: 0px 10px;
    }

    .business_points .your_points img {
        width: 40px;
    }

    .business_points .your_points {
        padding: 30px 0px 55px;
    }

    .business_points .your_points h6 {
        font-size: 20px;
    }

    .business_points .points_history h3 {
        font-size: 20px;
    }

}

@media only screen and (min-width: 768px) {
    .drag_drop_col_3.mobile {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .drag_drop_col_3.desktop {
        display: none;
    }
}

.report-radio-group label {
    box-shadow: 4px 4px 10px 0px #B8C4FF0D;
    padding: 24px;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    display: inline-flex;
    align-items: center;
    gap: 20px;
}

.report_issue_modal .modal-content {
    background-color: #FBF8FD;
}

.report-radio-group .form-check-input:checked[type=radio] {
    --bs-form-check-bg-image: url("../assets/images/radio.png");
}

.report-radio-group .form-check-input:checked {
    background-color: #DBE5FF;
    border-color: #002FA7;
    width: 20px;
    height: 20px;
}

.report-radio-group .form-check-input {
    width: 20px;
    height: 20px;
    background-size: 13px 13px;
    background-position: 3.5px 3px;
    padding: 10px;
    background-color: #DBE5FF;
}

.results_text {
    font-size: 50px !important;
}

.still_questions {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}

.still_questions a {
    color: #FDC93A;
}

.results_btn button:first-child {
    background-color: #DBE5FF;
    font-weight: 600;
    font-size: 18px;
    color: #000C2A;
    border: 0px;
    max-width: 200px;
    width: 100%;
    padding: 15px 0px;
    border-radius: 8px;
}

.results_btn button:last-child {
    background-color: #FDC93A;
    font-weight: 600;
    font-size: 18px;
    color: #000C2A;
    border: 0px;
    max-width: 200px;
    width: 100%;
    padding: 15px 0px;
    border-radius: 8px;
}

.gamified_quiz_results .quiz_user .ada_btn {
    background-color: #FDC93A;
    color: #001355;
    position: relative;
    z-index: 1;
    padding: 10px 8px;
}

.business_quiz_main .quiz_user p {
    font-size: 23px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 15px;
    margin-bottom: 10px;
    text-align: center;
}

.quiz_user_image {
    width: 130px;
    margin: auto;
}

.quiz_user_image.active .won_ring {
    position: absolute;
    top: -38px;
    left: 10px;
    display: block !important;
    width: 110px;
}

.correct_answers {
    width: 350px;
}

.correct_answers h5 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    font-weight: 700;
    color: #FEA800;
}

.training_topics_checkbox input {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #B3B3B3;
}

.training_topics_checkbox label {
    color: #2B2B2B;
    font-size: 14px;
    font-weight: 500;
}

.feedback_vector {
    padding-top: 100px;
}

@media only screen and (max-width: 767px) and (min-width: 0px) {
    .results_btn button {
        max-width: 100%;
    }

    .business_quiz_main .quiz_user p {
        font-size: 14px;
    }

    .quiz_user_image.active .won_ring {
        position: absolute;
        top: -32px;
        left: 20px;
        display: block !important;
        width: 90px;
        height: 117px;
    }

    .business_quiz_main .quiz_user .choose_quiz_user_image_circle {
        width: 80px;
        height: 80px;
    }

    .business_quiz_main .quiz_user p {
        font-size: 14px;
        margin-top: 0px;
    }

    .correct_answers {
        width: 100%;
    }

    .correct_answers h5 {
        font-size: 20px;
    }

}

@media only screen and (max-width: 374px) and (min-width: 0px) {
    .quiz_user_image.active .won_ring {
        position: absolute;
        top: -33px;
        left: 0px;
        display: block !important;
        width: 108px;
        height: 119px;
    }

    .quiz_user_image {
        width: 89px;
    }

}

.drag_drop_col_2 {
    height: 185px;
}

.drop-card {
    height: 90% !important;
    width: 100% !important;
    margin: 10px;
}

/* 10 Facts pages start */
.facts_card_title {
    background: linear-gradient(180deg, #FFE1E9 33.2%, #D2E23D 100%);
    padding: 80px 0px;
    text-align: center;
}

.facts_card_title h3 {
    font-size: 40px;
    font-weight: 600;
    color: #000C2A;
    margin-bottom: 10px;
}

.facts_card_title p {
    font-size: 16px;
    font-weight: 400;
    color: #000C2A;
    margin-bottom: 0px;
}

.facts_card_list h4 {
    color: #DBE5FF;
    font-size: 71px;
    font-weight: 700;
    margin-bottom: 0px;
    width: 100px;
}

.facts_card_list p {
    color: #000000;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0px;
}

.facts_details_step h6 {
    color: #002FA7;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 24px;
}

.facts_details_step h3 {
    color: #000C2A;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 35px;
}

.facts_details_step p {
    color: #000C2A;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 35px;
}

.question_card_box {
    background: linear-gradient(180deg, #FFE1E9 33.2%, #D2E23D 100%);
    padding: 0px 50px;
    text-align: center;
}

.question_card_box h3 {
    padding-top: 69px;
    padding-bottom: 69px;
    font-size: 48px;
    font-weight: 600;
    color: #000C2A;
}

.question_card_box img {
    padding-bottom: 69px;
}

.question_card_details h3 {
    color: #000C2A;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 14px;
}

.question_card_details p {
    color: #3A425B;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 40px;
}

.question_card_details h5 {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    margin-top: 25px;
}

.question_card_details h6 {
    color: #2B2B2B;
    font-size: 14px;
    font-weight: 500;
}

.certificate_card img {
    mix-blend-mode: multiply;
    padding-bottom: 30px;
}

.certificate_card h6 {
    font-size: 24px;
    font-weight: 600;
    color: #000C2A;
    margin-bottom: 12px;
}

.certificate_card h4 {
    font-size: 56px;
    font-weight: 600;
    color: #000C2A;
    padding-bottom: 70px;
}

.certificate_card_details .card {
    padding: 50px 30px;
    border: 0px;
}

.certificate_card_details span {
    font-size: 14px;
    font-weight: 400;
    color: #002FA7;
}

.certificate_card_details h2 {
    font-size: 24px;
    font-weight: 500;
    color: #000C2A;
    margin-top: 10px;
    margin-bottom: 20px;
}

.certificate_card_details p {
    font-size: 14px;
    font-weight: 400;
    color: #000C2A;
    margin-top: 10px;
    margin-bottom: 20px;
}

.certificate_card_details button {
    font-size: 18px;
    font-weight: 600;
    color: #000C2A;
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: #002FA7;
    color: #fff;
    padding: 16px 32px;
    border-radius: 8px;
    border: 0px;
}

.optional {
    color: #7E8294;
}

.mix-blend-mode-inherit {
    mix-blend-mode: inherit !important;
}

.your_rating_box {
    margin-bottom: 40px;
}

.your_rating_box h4 {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}

.skip_btn {
    background-color: transparent;
    border: 0;
    padding: 15px 40px;
    font-size: 18px;
    color: #002FA7;
    font-weight: 500;
}

@media only screen and (max-width: 991px) and (min-width: 768px) {

    .feedback_vector {
        padding-top: 30px;
    }
}

@media only screen and (max-width: 1119px) and (min-width: 0px) {
    .facts_details_step h3 {
        color: #000C2A;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 35px;
    }

    .question_card_box h3 {
        padding-top: 40px;
        padding-bottom: 40px;
        font-size: 32px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 0px) {
    .facts_card_title {
        padding: 40px 0px;
    }

    .facts_card_title h3 {
        font-size: 25px;
    }

    .facts_card_title p {
        font-size: 16px;
    }

    .facts_card_list h4 {
        font-size: 30px;
        width: auto;
    }

    .facts_card_list p {
        font-size: 16px;
    }

    .facts_details_step h6 {
        font-size: 16px;
    }

    .facts_details_step h3 {
        margin-bottom: 20px;
    }

    .question_card_box h3 {
        font-size: 25px;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .question_card_box {
        padding: 0px 15px;
    }

    .question_card_box img {
        padding-bottom: 20px;
    }

    .question_card_details h3 {
        font-size: 20px;
    }

    .question_card_details p {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .certificate_card h6 {
        font-size: 20px;
        margin-bottom: 12px;
    }

    .certificate_card h4 {
        font-size: 30px;
        padding-bottom: 40px;
    }

    .certificate_card_details .card {
        padding: 15px 10px;
        border: 0px;
    }

    .certificate_card_details button {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        padding: 10px 18px;
    }

    .feedback_vector {
        padding-top: 0px;
    }

    .skip_btn {
        padding: 15px 20px;
        font-size: 16px;
    }

    .btn-explore {
        padding: 15px 25px;
    }
}

/* 10 Facts pages  end */
/* Request Training */

.request-training h2 {
    font-size: 64px;
    font-weight: 400;
    color: #151515;
    margin-bottom: 10px;
}

.request-training h6 {
    font-size: 42px;
    font-weight: 400;
    color: #151515;
    margin-bottom: 10px;
}

.request-training ul {
    list-style-type: disc !important;
}

.request-training button,
.request-training a {
    background-color: #002FA7 !important;
    color: #fff !important;
    margin-top: 40px;
}

.request-training label {
    font-size: 16px;
    font-weight: 400;
    color: #000C2A;
    margin-bottom: 12px;
}

.request-training input:focus,
.request-training textarea:focus,
.request-training textarea,
.request-training input {
    font-size: 16px;
    font-weight: 400;
    color: #002FA7 !important;
    margin-bottom: 12px;
    background-color: #DBE5FF;
    padding: 10px;
}


.compliance-details h3 {
    color: #242424;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
}

.compliance-details h4 {
    color: #7a7a7a;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
    text-align: center;
}

.compliance-details h4 span {
    font-weight: 600;
}

.compliance-details h5 {
    color: #7a7a7a;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
    text-align: center;
}

.compliance-details h5 span {
    font-weight: 600;
    color: #7BC27B;
}

.compliance-details button {
    background-color: #dbe5ff;
    font-size: 14px;
    font-weight: 500;
    color: #002fa7;
    padding: 10px 15px;
    border-radius: 8px;
    border: 0;
}

.regulatory-compliance .progress,
.regulatory-compliance .progress-bar {
    height: 6px;
}

.regulatory-compliance .progress-number {
    color: #5F5E62;
    font-size: 12px;
    font-weight: 400;
}

.regulatory-compliance .error-line {
    color: #D76B66;
    font-size: 12px;
    font-weight: 500;
}

.environmental-color {
    background-color: #FFC775;
}

.leaderboard-table th,
.leaderboard-table td {
    text-align: center;
    border: 0px;
}

.request-online-training h6 {
    font-size: 32px;
    font-weight: 400;
}

.request-online-training h3 {
    font-size: 16px;
    font-weight: 600;
    color: #002FA7;
    margin-bottom: 16px;
}

.request-training .form-select {
    font-size: 16px;
    font-weight: 400;
    color: #002FA7 !important;
    margin-bottom: 12px;
    background-color: #DBE5FF;
    padding: 10px;
}

.request-online-training{
    max-width: inherit !important;
}

@media only screen and (max-width: 1399px) and (min-width: 992px) {
    .request-training h2 {
        font-size: 40px;
    }

    .request-training button,
    .request-training a {
        background-color: #002FA7 !important;
        color: #fff !important;
        margin-top: 0px;
        margin-bottom: 20px;
    }

    .request-training h6 {
        font-size: 30px;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 992px) {
    .request-training-img {
        width: 300px;
    }

}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .request-training h2 {
        font-size: 30px;
    }

    .request-training ul li {
        font-size: 14px;
    }

    .vh-lg-100 {
        height: 65vh !important;
    }

    .business_public_spaces .right_side_information {
        padding-top: 0px;
    }

    .request-training h6 {
        font-size: 24px;
    }


    .request-training button,
    .request-training a {
        background-color: #002FA7 !important;
        color: #fff !important;
        margin-top: 0px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .request-training h2 {
        font-size: 25px;
    }

    .request-training button {
        margin-bottom: 30px;
    }

    .request-training ul li {
        font-size: 14px;
    }

    .request-training-img {
        width: 300px !important;
    }

    .request-training h6 {
        font-size: 24px;
    }

    .request-training button,
    .request-training a {
        background-color: #002FA7 !important;
        color: #fff !important;
        margin-top: 0px;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 1200px) {
    .business_public_spaces .right_side_information.request-training {
        padding-left: 0px;
        padding-right: 90px;
        max-width: inherit;
    }
}

@media only screen and (min-width: 768px) {
    .position-sm-absolute {
        position: absolute;
    }
}

@media only screen and (min-width: 992px) {
    .vh-lg-100 {
        height: 100vh !important;
    }
}