:root {
    --bs-primary: #002FA7 !important;
    --bs-secondary: #DBE5FF !important;
    --card-width: 200px;
    --card-height: 300px;
    --num-cards: 5;
    --offset-amount: 10px;
    --xaxis-start-point: 46%;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    outline: 0;
}

.btn-close:focus {
    outline: 0;
    box-shadow: none;
    opacity: var(--bs-btn-close-focus-opacity);
}

body {
    margin: 0px;
    padding: 0px;
    font-family: 'Poppins', sans-serif;
    background-color: #fbf8fd;
}

/* #GENERAL CSS START */

@media only screen and (max-width: 2560px) and (min-width: 1440px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1400px;
    }
}

a {
    text-decoration: none !important;
}

.accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: none;
}

.accordion-items-shadow {
    box-shadow: 4px 4px 20px 0px rgba(184, 196, 255, 0.25) !important;
}

.border-primary {
    --bs-border-opacity: 1;
    border-color: var(--bs-primary) !important;
}

.text-black-primary {
    color: #001854;
}

.text_primary {
    color: var(--bs-primary) !important;
}

.bg-secondary {
    background-color: var(--bs-secondary) !important;
}

.bg-primary {
    background-color: var(--bs-primary) !important;
}

.text-muted {
    --bs-text-opacity: 1;
    color: #5F5E62 !important;
}

::-webkit-scrollbar-track {
    border-radius: 0px;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--bs-secondary);
}

.bg-black {
    background-color: #000C2A !important;
}

.bg-body {
    background-color: #fbf8fd !important;
}

.breadcrumb-main {
    margin-top: 40px;
}

.breadcrumb-main .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, ">");
}

.breadcrumb {
    align-items: center;
}

.breadcrumb-main .breadcrumb-item a {
    color: #000;
    font-weight: 400;
    font-size: 14px;
}

.breadcrumb-item.active {
    color: var(--bs-primary);
    font-weight: 400;
    font-size: 14px;
}

.offcanvas-body::-webkit-scrollbar {
    display: none !important;
}

.modal-body::-webkit-scrollbar {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.form-control {
    font-weight: 500;
    color: #000 !important;
    box-shadow: none !important;
}

select {
    padding-right: 40px !important;
}

.btn {
    font-weight: 500;
    color: #000 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fs-7 {
    font-size: 14px !important;
}

.fs-8 {
    font-size: 13px !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-19 {
    font-size: 19px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-21 {
    font-size: 21px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-23 {
    font-size: 23px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.wh-20 {
    width: 20px !important;
    height: 20px !important;
}

.wh-25 {
    width: 24px !important;
    height: 24px !important;
}

.wh-40 {
    width: 40px !important;
    height: 40px !important;
}

.text-black {
    color: #000C2A !important;
}

.text-bl {
    color: #000 !important;
}

.text-primary {
    color: var(--bs-primary) !important;
}

.text-secondary {
    color: #8097d3 !important;
}

.btn-primary {
    background-color: var(--bs-primary);
    border: 0;
    padding: 15px 25px;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
}

.btn-secondary {
    background-color: var(--bs-secondary);
    border: 0;
    padding: 15px 25px;
    font-size: 18px;
    color: #000;
    font-weight: 500;
}

.btn-explore {
    background-color: #000C2A;
    border: 0;
    padding: 15px 25px;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
}

.section-title h2 {
    color: #000;
}

.shadow-right {
    box-shadow: 4px 4px 10px 0px rgba(0, 24, 84, 0.05);
}

.bg-light-input,
.bg-light-input .form-control:focus {
    background-color: #F5F3F7 !important;
}

.break-all {
    word-break: break-all;
}

.nav-link:focus-visible {
    box-shadow: none !important;
}

/* #GENERAL CSS START */

/* HEADER SECTION CSS START */

.header-logo {
    padding-top: 0px;
    padding-bottom: 0px;
}

.header-logo img {
    width: 150px;
    height: 36px;
    padding-right: 10px;
}

.header_main {
    background-color: var(--bs-primary);
    padding: 10px 0px;
}

.header_main .nav-item .nav-link {
    color: #fff;
    padding: 0px 10px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}

.header-form input {
    padding: 13px 0px;
}

.start-btn {
    background-color: #D2E23D;
    border: 0;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    padding: 0px 20px;
}

.header-form .form-control {
    background-color: var(--bs-secondary);
    font-weight: 400;
    font-size: 16px;
}

.header-form input::placeholder {
    color: #000C2A !important;
}

.search-icon img,
.translate img {
    width: 24px;
    height: 24px;
    margin-left: 5px;
}

/* HEADER SECTION CSS END */

/* HOME SECTION CSS START */
.home-text-box a {
    font-size: 18px;
    font-weight: 400;
    padding: 13px 15px;
}

@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .home-main {
        background-color: var(--bs-primary);
        height: 100vh;
        margin-top: -1px;
    }

    .working-girl {
        position: absolute;
        top: 30%;
    }

    .home-slider .badge {
        font-size: 14px;
        font-weight: 400;
        background-color: var(--bs-secondary);
        color: var(--bs-primary);
        padding: 10px 20px;
    }

    .slick-slide {
        margin: 10px;
    }

    .home-text-box img {
        width: 30px;
        height: 30px;
    }

    .categorie-img {
        width: 100px;
        height: 100px;
    }

    .slick-list {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .header-form {
        width: 520px;
    }

    .home-main {
        position: relative;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 1441px) {
    .home-text-box h1 {
        padding-top: 100px;
        font-size: 64px;
    }

    .vector-1 {
        mix-blend-mode: multiply;
        position: absolute;
        top: 8%;
        width: 530px;
        height: 700px;
    }

    .vector-2 {
        mix-blend-mode: multiply;
        position: absolute;
        top: 20%;
        right: 0%;
        width: 500px;
    }

    .working-girl {
        position: absolute;
        top: 22%;
        left: -4%;
        width: 500px;
        height: 400px;
    }

    .online-presentation {
        position: absolute;
        top: 0%;
        right: 0%;
        width: 400px;
        height: 400px;
    }

    .home-slider {
        position: absolute !important;
        top: 70%;
        left: 0;
        right: 0;
    }

    .home-slider h5 {
        font-size: 32px;
    }

    .categories-main {
        padding-top: 26rem;
        padding-bottom: 3rem;
    }

}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
    .home-text-box h1 {
        font-size: 45px;
        padding-top: 140px;
    }

    .vector-1 {
        mix-blend-mode: multiply;
        position: absolute;
        top: 22%;
        width: 450px;
    }

    .vector-2 {
        mix-blend-mode: multiply;
        position: absolute;
        top: 22%;
        right: 0%;
        width: 320px;
    }

    .working-girl {
        position: absolute;
        top: 28%;
        left: -5%;
        width: 450px;
        height: 400px;
    }

    .online-presentation {
        position: absolute;
        top: 0%;
        right: 0%;
        width: 340px;
        height: 350px;
    }

    .home-slider h5 {
        font-size: 20px;
    }

    .home-slider {
        position: absolute !important;
        top: 80%;
        left: 0;
        right: 0;
    }

    .categories-main {
        padding-top: 27rem;
        padding-bottom: 4rem;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {

    .home-text-box h1 {
        padding-top: 120px;
        font-size: 40px;
    }

    .home-slider {
        position: absolute !important;
        top: 50%;
        left: 0;
        right: 0;
    }

    .categories-main {
        padding-top: 23rem;
        padding-bottom: 4rem;
    }

    .section-title h2 {
        margin-top: 20px;
        margin-bottom: 50px;
        font-size: 30px;
        font-weight: 400;
        text-align: center;
    }

    .home-main {
        height: 75vh;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {

    .home-main {
        background-color: var(--bs-primary);
        height: 60vh;
    }

    .home-text-box h1 {
        padding-top: 40px;
        font-size: 35px;
    }

    .slick-slide {
        margin: 10px;
    }

    .home-slider {
        position: absolute !important;
        top: 50%;
        left: 0;
        right: 0;
    }

    .sm-none {
        display: none !important;
    }

    .categories-main {
        padding-top: 22rem;
        padding-bottom: 3rem;
    }

    .home-slider .badge {
        font-size: 15px;
        font-weight: 400;
        background-color: var(--bs-secondary);
        color: var(--bs-primary);
        padding: 10px 20px;
    }

    .position-sm-relative {
        position: relative;
    }
}

@media only screen and (max-width: 402px) and (min-width: 376px) {
    .home-slider {
        position: absolute !important;
        top: 60%;
        left: 0;
        right: 0;
    }

    .categories-main {
        padding-top: 25rem;
    }
}

@media only screen and (max-width: 375px) and (min-width: 320px) {
    .home-slider {
        position: absolute !important;
        top: 60%;
        left: 0;
        right: 0;
    }

    .categories-main {
        padding-top: 26rem;
    }
}

/* HOME SECTION CSS END */

/* CATEGORIES SECTION CSS START */

.section-title span {
    font-size: 16px;
    font-weight: 400;
    color: var(--bs-primary);
    text-align: center;
    margin: auto;
}


.categorie-row .card {
    background-color: #B8CCFF;
    cursor: pointer;
}

.categorie-card-left {
    height: 100%;
    background-color: var(--bs-secondary);
    display: flex;
}

.categorie-row h5 {
    color: #000C2A;
}

.categorie-row h5 {
    font-size: 16px;
    font-weight: 400;
}

@media only screen and (max-width: 2560px) and (min-width: 1024px) {
    .categorie-img {
        width: 90px;
        height: 90px;
    }

    .section-title h2 {
        margin-top: 20px;
        margin-bottom: 50px;
        font-size: 48px;
        font-weight: 400;
        text-align: center;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {

    .categorie-img {
        width: 50px;
        height: 50px;
    }

    .section-title h2 {
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 25px;
        font-weight: 500;
        text-align: center;
    }

    .categorie-row h5 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/* CATEGORIES SECTION CSS START */
/* FEATURED SECTION CSS START */
.badge-warning {
    color: #000 !important;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 14px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    cursor: default;
}

.badge-light {
    background-color: var(--bs-secondary);
    color: #000;
    padding: 10px 20px;
}

.badge-black {
    background-color: #000C2A;
    color: #000;
    padding: 10px 20px;
    display: block;
    text-wrap: wrap;
}

.badge-black-2 {
    background-color: var(--bs-secondary);
    color: #000C2A !important;
    padding: 10px 20px;
    cursor: pointer;
}

.badge-gold {
    background-color: #F8AA13;
    color: #fff;
    padding: 10px 20px;
}

.badge-bronze {
    background-color: #CD6849;
    color: #fff;
    padding: 10px 20px;
}

.badge-silver {
    background-color: #7E90B5;
    color: #fff;
    padding: 10px 20px;
}

.featured-card h5 {
    color: #000;
    margin-top: 20px;
    font-size: 24px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 55px;
}

.rectangle li {
    width: 30px;
    height: 6px;
    background-color: var(--bs-secondary);
    border-radius: 3px;
    list-style-type: none;
}

.rectangle li.active {
    background-color: #000;
}

.rectangle2 li.active {
    background-color: var(--bs-primary);
}

.featured-main .accordion-button:not(.collapsed)::after {
    --bs-accordion-btn-icon-transform: rotate(-0deg);
}

.course-accordion button {
    font-size: 16px;
    font-weight: 400;
}

.featured-main .accordion-button:focus {
    box-shadow: none;
}

.featured-main .accordion-button {
    background-color: #000C2A !important;
    color: #fff !important;
    padding: 25px;
}

.featured-main .accordion-button::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    width: 20px;
    height: 20px;
}

.featured-main .accordion-top-text {
    background-color: var(--bs-secondary);
    margin-bottom: 0px;
}

.featured-main .accordion-collapse button:nth-child(2) {
    color: #fff;
    padding: 22px;
}

@media only screen and (max-width: 2560px) and (min-width: 1024px) {

    .featured-main {
        padding-top: 2rem;
        padding-bottom: 4rem;
    }

    .featured-card .card-body {
        padding: 19px;
    }

    .featured-img {
        height: 350px;
    }

    .featured-details-img {
        height: 550px;
    }

    .mb-lg-6 {
        margin-bottom: 4.5rem !important
    }

    .position-lg-sticky {
        position: sticky;
        top: 25px;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .featured-main {
        padding-bottom: 3rem;
    }

    .featured-img {
        height: 250px;
    }

    .featured-details-img {
        height: 400px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .featured-main {
        padding-bottom: 2rem;
    }

    .featured-img {
        height: 250px;
    }

    .featured-details-img {
        height: 250px;
    }
}

/* FEATURED SECTION CSS END */
/* UNIQUE ENDGE SECTION START */
.unique-edge-main .card img {
    height: 500px;
    margin: auto;
    display: flex;
}

@media only screen and (max-width: 2560px) and (min-width: 1441px) {
    .unique-edge-main {
        padding-bottom: 4rem;
    }

    .yellow-vector {
        position: absolute;
        right: 0%;
        top: 6%;
        width: 400px;
        height: auto;
        z-index: -1;
    }

    .pink-vector {
        position: absolute;
        left: 0%;
        top: 30%;
        width: 220px;
        height: auto;
        z-index: -1;
    }

    .light-grenn-vector {
        position: absolute;
        right: 0%;
        bottom: -7%;
        width: 440px;
        height: 900px;
        z-index: -1;
    }
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
    .unique-edge-main {
        padding-bottom: 3rem;
    }

    .yellow-vector {
        position: absolute;
        right: 0%;
        top: 7%;
        width: 340px;
        height: auto;
        z-index: -1;
    }

    .pink-vector {
        position: absolute;
        left: 0%;
        top: 31%;
        width: 220px;
        height: auto;
        z-index: -1;
    }

    .light-grenn-vector {
        position: absolute;
        right: 0%;
        bottom: -1%;
        width: 350px;
        height: 850px;
        z-index: -1;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .unique-edge-main {
        padding-bottom: 4rem;
    }

    .yellow-vector {
        position: absolute;
        right: 0%;
        top: 19%;
        width: 300px;
        height: auto;
        z-index: -1;
    }

    .pink-vector {
        position: absolute;
        left: 0%;
        top: 32%;
        width: 200px;
        height: auto;
        z-index: -1;
    }

    .light-grenn-vector {
        position: absolute;
        right: 0%;
        bottom: 14%;
        width: 250px;
        z-index: -1;
    }
}

@media only screen and (max-width: 768px) and (min-width: 2560px) {
    .unique-edge-main .card img {
        height: 500px;
        margin: auto;
        display: flex;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .unique-edge-main {
        padding-bottom: 2rem;
    }

    .unique-edge-main .card img {
        height: 300px;
        margin: auto;
        display: flex;
    }

    .yellow-vector {
        display: none;
    }

    .pink-vector {
        display: none;
    }

    .light-grenn-vector {
        display: none;
    }

    .sm-w-100 {
        width: 100%;
    }
}

/* UNIQUE ENDGE SECTION EMD */
/* PRICUNG SECTION START */
.pricing-tabs .nav-item a {
    padding: 15px 20px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    background-color: #fff;
    color: var(--bs-primary);
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.pricing-tabs .nav-item a.active {
    background-color: var(--bs-primary) !important;
    color: #fff;
}

.pricing-tabs .nav-link:focus,
.pricing-tabs .nav-link:hover {
    isolation: isolate;
    border-color: transparent;
}

.pricing-table td {
    padding: 30px 0px;
}

.pricing-table th {
    font-size: 18px;
    font-weight: 600;
    padding: 30px;
    text-align: center;
}

.pricing-table td svg {
    display: block;
    margin: auto;
}

.pricing-table td button {
    display: flex;
    margin: auto;
    white-space: nowrap;
}

.pricing-table tbody tr {
    border-bottom: 1px solid var(--bs-secondary);
}

@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .pricing-main {
        padding-bottom: 4rem;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .pricing-main {
        padding-bottom: 3rem;
    }
}

/* PRICUNG SECTION END */

/* TESTIMONIALS SECTION START */

.testimonials-main img {
    width: 40px;
    height: 40px;
}

.testimonials-main h5 {
    font-size: 18px !important;
    font-weight: 600;
}

.testimonials-main .text-muted {
    font-size: 16px !important;
    font-weight: 400 !important;
}

.testimonials-main .text-muted {
    font-size: 16px !important;
    font-weight: 400 !important;
}

.testimonials-main .text-primary {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.testimonials-main .card .card-body {
    padding: 30px;
}

@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .testimonials-main {
        padding-bottom: 4rem;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .testimonials-main {
        padding-bottom: 3rem;
    }
}


/* TESTIMONIALS SECTION END */

/* FAQ SECTION START */
.faq-main .accordion-button::after {
    content: "+";
    color: #000C2A;
    transform: scale(1.5) !important;
    background-image: none;
    padding-left: 10px;
}

.faq-main .accordion-button:not(.collapsed)::after {
    content: "-";
    color: var(--bs-primary);
}

.accordion-button:not(.collapsed) {
    box-shadow: none;
    border-bottom: 1px solid #dee2e6;
    background-color: transparent;
    color: var(--bs-primary);
}

.accordion-item {
    padding: 10px 0px;
}

.accordion-button {
    font-weight: 600;
    font-size: 16px;
}

.faq-main .accordion-button:focus {
    border-color: var(--bs-secondary) !important;
    box-shadow: none !important;
}


@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .faq-main {
        padding-bottom: 4rem;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .faq-main {
        padding-bottom: 3rem;
    }
}

/* FAQ SECTION END */

/* LEARNING ADVENTURE SECTION START */

.subscribe::placeholder {
    font-size: 16px;
    font-weight: 500;
    color: #cfcfd0 !important;
}

.learning-adventure-main {
    background-color: var(--bs-secondary);
}

@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .learning-adventure-main {
        padding: 4rem 0rem;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .learning-adventure-main {
        padding: 3rem 0rem;
    }
}

/* LEARNING ADVENTURE SECTION END */
/* FOOTER SECTION START */
.footer-main {
    background-color: #fff;
}

.footer-input {
    background-color: var(--bs-secondary);
}

.footer-btn-list li {
    list-style-type: none;
}

.footer-btn-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.footer-btn-list li button {
    font-weight: 400;
    font-size: 16px !important;
    padding: 15px !important;
}

.social-icon img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.subscribe-box input {
    padding: 15px 25px;
}

@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .footer-main {
        background-color: #fff;
        padding: 5rem 0rem;
    }

    .footer-logo {
        width: 130px;
        height: 116px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .footer-main {
        background-color: #fff;
        padding: 2rem 0rem;
    }

    .footer-logo {
        width: 80px;
        height: 70px;
    }

    .subscribe-box input {
        border-bottom-right-radius: 0.375rem !important;
        border-top-right-radius: 0.375rem !important;
    }

    .subscribe-box button {
        border-bottom-left-radius: 0.375rem !important;
        border-top-left-radius: 0.375rem !important;
    }
}

@media only screen and (max-width: 1399px) and (min-width: 768px) {
    .footer-main .col-xl-5 {
        flex: 0 0 auto;
        width: 100%;
    }

    .footer-main .col-xl-7 {
        flex: 0 0 auto;
        width: 100%;
    }

    .sidebar-mian {
        display: none !important;
    }

    .admin-toggel {
        display: block !important;
    }

    .responsive-sidebar {
        display: block !important;
    }
}


/* FOOTER SECTION END */
/* ==================================================== COURSES PAGE ====================================================== */
/* COURSES HOME SECTION START */
.courses-categories {
    background-color: var(--bs-primary);
    height: 500px;
}

.header2-main {
    background-color: #fff;
    padding: 10px 0px;
}

.header2-main .nav-item .nav-link {
    color: #000;
    padding: 0px 8px;
    font-size: 16px;
    font-weight: 400;
}

.header2-main .offcanvasRight {
    color: #000 !important;
}

.subscribe-box .form-control:focus {
    background-color: var(--bs-secondary);
    box-shadow: none !important;
}

.numbers-card .card-body {
    display: flex;
    align-items: center;
}

.numbers-card h2 {
    color: #001854;
    font-size: 3rem;
}

.numbers-card p {
    color: #001854;
}

.courses-main .subscribe-box .form-control {
    font-weight: 300 !important;
}

.courses-main .subscribe-box ::placeholder {
    color: #B8CCFF !important;
    font-weight: 300 !important;
}

@media only screen and (max-width: 2560px) and (min-width: 993px) {
    .courses-vector-1 {
        position: absolute;
        bottom: 0%;
        left: 0%;
        width: 430px;
        height: 400px;
    }

    .courses-gift {
        position: absolute;
        left: -7%;
        bottom: -15%;
        z-index: 1;
        width: 450px;
    }

    .courses-vector-2 {
        position: absolute;
        top: 0%;
        right: 0%;
    }

    .juicy-online {
        position: absolute;
        bottom: -7%;
        right: -2%;
        z-index: 1;
        width: 320px;
    }

    .courses-main {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .courses-vector-1 {
        position: absolute;
        bottom: 0%;
        left: 0%;
        width: 300px;
        height: 300px;
    }

    .courses-gift {
        position: absolute;
        left: -14%;
        bottom: -20%;
        z-index: 1;
        width: 400px;
    }

    .courses-vector-2 {
        position: absolute;
        top: 0%;
        right: 0%;
        width: 200px;
    }

    .juicy-online {
        position: absolute;
        bottom: -7%;
        right: -2%;
        z-index: 1;
        width: 280px;
    }

    .courses-main {
        margin-top: 3rem;
        margin-bottom: 4rem;
    }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .courses-vector-1 {
        display: none;
    }

    .courses-gift {
        display: none;
    }

    .courses-vector-2 {
        display: none;
    }

    .juicy-online {
        display: none;
    }

    .courses-main {
        margin-top: 2rem;
    }

    .header-logo img {
        width: 100px;
        height: 25px;
    }

}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
    .courses-home {
        padding-top: 3rem;
    }
}


@media only screen and (max-width: 767px) and (min-width: 320px) {
    .numbers-card h2 {
        color: #001854;
        font-size: 2rem;
    }
}

/* COURSES HOME SECTION END */

/* FEATURED COURSESB SECTION START */
.featured-courses-main {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.featured-courses-main ul li {
    list-style-type: none;
    padding: 23px 10px;
}

.border-bottom-list {
    border-bottom: 1px solid var(--bs-secondary) !important;
}

.featured-courses-img {
    height: 300px;
    object-fit: cover;
}

.featured-courses-main .rectangle li {
    padding: 0px !important;
    width: 18px !important;
}

.featured-courses-main ul {
    background-color: #fff;
}


.owl-dots .owl-dot.active span {
    background: #B8CCFF !important;
    width: 30px !important;
    height: 5px !important;
}

.owl-dots .owl-dot span {
    width: 5px !important;
    height: 5px !important;
    background: #B8CCFF !important;
}

.owl-dots .owl-dot:hover span {
    background: #B8CCFF !important;
}

.owl-dots .owl-dot span {
    background: var(--bs-secondary);
}

.courses-list-left-sidebar {
    background-color: var(--bs-secondary);
    color: #000;
    width: 100px;
    border: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
    width: 80px;
}

.item {
    transition: all 0.2s ease-in-out;
}

.home-slider .center {
    z-index: 9999;
}

.home-slider .owl-stage {
    padding: 50px 0px !important;
}

.home-slider img {
    height: 300px;
    object-fit: cover;
}

@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .home-slider .center .item {
        transform: scale(1.1);
        margin: 0px 45px 0px !important;
    }

    .home-slider .item {
        transform: scale(0.9);
    }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .home-slider .center .item {
        margin: 0px 10px 0px !important;
    }

    .home-slider .owl-stage {
        padding: 50px 0px 20px !important;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 1440px) {
    .featured-courses-row .col-xl-2 {
        width: 18%;
        display: block !important;
    }

    .featured-courses-row .col-xl-10 {
        width: 82%;
    }

    .featured-courses-main ul {
        width: 230px;
    }

    .bar {
        display: none;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 1024px) {
    .owl-nav.disabled+.owl-dots {
        margin-top: 70px !important;
    }

    .featured-courses-carousel-1 {
        padding-bottom: 5rem;
    }

    .featured-courses-carousel-2 {
        padding-bottom: 5rem;
    }

    .featured-courses2-img {
        height: 400px;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .featured-courses-main ul {
        position: sticky;
        top: 0px;
    }
}

@media only screen and (max-width: 992px) and (min-width: 280px) {

    .owl-nav.disabled+.owl-dots {
        margin-top: 40px !important;
    }

    .featured-courses-carousel-2 .owl-nav.disabled+.owl-dots {
        margin-top: 40px;
    }

    .featured-courses-carousel-1 {
        padding-bottom: 3rem;
    }

    .featured-courses-carousel-2 {
        padding-bottom: 3rem;
    }

    .featured-courses2-img {
        height: 300px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .sm-mb-3 {
        margin-bottom: 1.5rem !important;
    }
}

/* FEATURED COURSESB SECTION END */

/* ==================================================== COURSES PAGE ====================================================== */
/* ==================================================== COURSES CATRGORY PAGE ====================================================== */

.courses-tabs-bar .nav-item a {
    padding: 10px 15px;
    color: #000C2A !important;
    font-weight: 400;
    font-size: 16px;
    background-color: var(--bs-secondary);
    display: inline-block;
    white-space: nowrap;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #b8ccff !important;
}

.rectangle3 li {
    width: 30px !important;
}

.rectangle3 li.active {
    background-color: var(--bs-primary) !important;
}

.featured-courses2-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bottom-dots li {
    width: 5px;
    height: 5px;
    background-color: var(--bs-secondary);
    padding: 0px !important;
    border-radius: 5px;
}

.bottom-dots .active {
    width: 30px !important;
    height: 5px !important;
    background-color: #B8CCFF !important;
}

.navbar-nav .nav-link.active {
    color: var(--bs-primary) !important;
}

.navbar-nav .nav-link.active .dropdown-toggle {
    color: var(--bs-primary) !important;
}

.btn-active li button.active {
    font-weight: 400;
    padding: 15px !important;
    border: 1px solid var(--bs-primary) !important;
}



@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .accordionmain::before {
        content: "";
        position: absolute;
        height: calc(100% - 55px);
        width: 2px;
        border: 1px dashed var(--bs-primary);
        left: 20px;
        top: 25px;
    }

    .accordionmain {
        padding-left: 50px;
    }

    .accordionmain .accordion-item::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: var(--bs-secondary);
        border: 2px solid var(--bs-secondary);
        border-radius: 50%;
        left: -35px;
        top: 25px;
    }

    .accordionmain .accordion-item {
        position: relative;
    }

    .accordion-images-main {
        padding-top: 3rem;
        padding-bottom: 4rem;
    }

    .accordion-image {
        height: 425px;
    }
}

.accordionmain .accordion-button::after {
    background-image: url("../assets/images/top-aroow.png") !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 12px;
    height: 7.4px;
}

.preview-course-faq .accordion-button::after {
    background-image: url("../assets/images/bottom-aroow2.png") !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 19.069px;
    height: 11px;
}

.accordionmain .accordion-button {
    border: 0;
}

.answer-list {
    padding-top: 30px;
    padding-left: 23px;
}

.answer-list li {
    list-style-type: none;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px;
    border-radius: 8px;
}

.linear-gradient {
    background: linear-gradient(180deg, #FFE1E9 33.2%, #D2E23D 100%) !important;
}

.girlimge img {
    mix-blend-mode: multiply;
    width: 70%;
}

.btn-light-warning {
    background-color: #D2E23D !important;
}

.girlimge {
    margin-bottom: 3rem !important;
}

.references-list li {
    list-style: decimal;
    margin-bottom: 20px;
    color: #5F5E62 !important;
    font-size: 14px;
    word-break: break-all;
}

.references-list-2 li {
    list-style: none;
}

.references-list li a {
    cursor: auto;
}

.references-list q {
    word-break: break-all;
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .accordion-image {
        height: 250px;
    }

    .p-sm-15 {
        padding: 15px 15px !important;
    }

    .fs-sm-6 {
        font-size: 14px !important;
    }

    .fs-sm-7 {
        font-size: 12px !important;
    }

    .fs-sm-5 {
        font-size: 17px !important;
    }

    .girlimge {
        margin-bottom: 3em !important;
    }

    .girlimge img {
        width: 100%;
    }
}

/* ==================================================== COURSES CATRGORY PAGE ====================================================== */
/* ==================================================== INDIVIDUAL PRICING PAGE====================================================== */

.individual-pricing,
.team-pricing {
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-bottom: 1rem;
}

.individual-pricing button,
.team-pricing button {
    border: 0;
    padding: 15px 25px;
}

.individual-pricing .individual-pricing-vector-1 {
    position: absolute;
    bottom: 52%;
    left: 47px;
    width: 50%;

}

.individual-pricing .individual-pricing-vector-2 {
    position: absolute;
    top: 60%;
    left: 56%;
    width: 50%;
}

.sec-pt-5 {
    padding-top: 3rem;
}

.home-girl-png,
.boy-and-girl {
    width: 100%;
}

@media only screen and (max-width: 2560px) and (min-width: 1440px) {
    .individual-pricing {
        height: 90vh;
    }

    .sec-pt-5 {
        padding-top: 4rem;
    }
}

@media only screen and (max-width: 1439px) and (min-width: 768px) {
    .individual-pricing {
        height: 70vh;
    }

    .sec-pt-5 {
        padding-top: 3rem;
    }

    .navbar-expand-xl .navbar-collapse {
        display: none !important;
    }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .individual-pricing .order-2 {
        padding-top: 90px;
    }

    .sec-pt-5 {
        padding-top: 3rem;
    }

    .individual-pricing .individual-pricing-vector-2 {
        left: 45%;
    }
}

/* ==================================================== INDIVIDUAL PRICING PAGE ====================================================== */
/* ==================================================== TEAM PRICING PAGE ====================================================== */
.team-pricing {
    background-color: #00237D;
}


.team-pricing .team-pricing-vector-3 {
    position: absolute;
    bottom: 60%;
    left: 60%;
    width: 50%;
}

.team-pricing .team-pricing-vector-4 {
    position: absolute;
    top: 60%;
    left: 4%;
    width: 50%;
}

.learn-pricing-sec button {
    border: 0;
    padding: 15px 25px;
}

.categories-owl img {
    width: 90px !important;
    height: 90px;
}

.categories-owl .owl-nav.disabled+.owl-dots {
    margin-top: 50px;
}

@media only screen and (max-width: 2560px) and (min-width: 1440px) {
    .team-pricing {
        height: 90vh;
    }

}

@media only screen and (max-width: 1440px) and (min-width: 768px) {
    .team-pricing .team-pricing-vector-3 {
        left: 50%;
    }
}

@media only screen and (max-width: 1439px) and (min-width: 768px) {
    .team-pricing {
        height: 70vh;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .learn-pricing-sec {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .team-pricing .order-2 {
        padding-top: 90px;
    }

    .learn-pricing-sec {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .team-pricing .team-pricing-vector-3 {
        left: 45%;
    }
}

/* ==================================================== TEAM PRICING PAGE ====================================================== */
/* ==================================================== TEAM COMMUNITY PAGE ====================================================== */
.community-letf-side li.active {
    background-color: var(--bs-secondary);
}

.community-text-box li {
    font-size: 14px;
    color: #5F5E62;
    list-style: decimal !important;
    padding: 0px 0px !important;
}


.text-black-placeholder::placeholder {
    color: #000C2A !important;
    font-weight: 400;
}

.help-card img {
    width: 24px;
    height: 24px;
}

.help-card h2 {
    font-size: 32px;
    color: #000C2A;
}

.help-center-topic-list-1 .topic-list {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    list-style: decimal;
}

.help-center-topic-option li {
    list-style-type: disc !important;
    padding: 5px 0px !important;
}

/* ==================================================== TEAM COMMUNITY PAGE ====================================================== */
/* ==================================================== SING UP PAGE ====================================================== */

.sing-vector-1 {
    position: absolute;
    top: 0%;
    left: 0%;
}

.sing-vector-2 {
    position: absolute;
    bottom: 0%;
    right: 0%;
}

.form-main .form-control,
.form-main .form-select {
    padding: 15px;
    border: 0;
    box-shadow: 4px 4px 10px 0px rgba(184, 196, 255, 0.05);
}

.form-main label {
    color: #000C2A;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 8px;
}


.form-main .form-control:active,
.form-main .form-control:focus,
.form-main .form-control:focus:active {
    background-color: var(--bs-secondary) !important;
    border-color: var(--bs-secondary);
    color: var(--bs-primary) !important;
}

.form-main .form-control {
    color: var(--bs-primary) !important;
}


.form-main .form-control:valid {
    background-color: var(--bs-secondary) !important;
}

.card-input-element:checked+.card-input {
    box-shadow: 0 0 1px 1px var(--bs-secondary) !important;
}

@media only screen and (max-width: 2560px) and (min-width: 1441px) {
    .selectcard .col-12 {
        width: 72%;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 1024px) {
    .sign-up-main .sign-up-col {
        height: 100vh;
    }

    .project-team {
        width: 70%;
    }

    .pt-md-12 {
        padding-top: 12rem !important;
    }

    .pt-mb-20 {
        padding-top: 20rem;
    }

    .individual-main .money-stream {
        width: 50%;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {

    .individual-main .man-develops {
        width: 50%;
    }

    .individual-main .money-stream {
        width: 35%;
    }

    .project-team {
        width: 90%;
    }

    .pt-md-12 {
        padding-top: 10rem !important;
    }

    .individual-main .sign-up-col {
        height: 80vh;
    }

    .pt-mb-20 {
        padding-top: 10rem;
    }

}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .sign-up-main .sign-up-col {
        height: 80vh;
    }

    .individual-main .sing-vector-1 {
        width: 30%;
    }

    .individual-main .sing-vector-2 {
        width: 40%;
    }

    .sing-vector-1 {
        width: 50%;
    }

    .sing-vector-2 {
        width: 80%;
    }

    .project-team {
        width: 80%;
    }

    .vh-md-100 {
        height: 100vh !important;
    }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .sign-up-main .sign-up-col {
        height: 70vh;
    }

    .sing-vector-1 {
        width: 30%;
    }

    .sing-vector-2 {
        width: 50%;
    }

    .project-team {
        width: 80%;
    }

    .sign-up-main .sign-up-col-sm {
        padding-top: 50px;
        padding-bottom: 50px;
        height: auto !important;
    }

    .individual-main .man-develops {
        width: 90%;
    }

    .individual-main .money-stream {
        width: 70%;
    }

    .pt-mb-20 {
        padding-top: 5rem;
    }

    .pt-md-12 {
        padding-top: 5rem !important;
    }
}

/* ==================================================== SING UP PAGE ====================================================== */
/* ==================================================== DASHBAORD PAGE ====================================================== */
.admin-main {
    background-color: #fff;
}

.header-shadow {
    box-shadow: 4px 4px 20px 0px rgba(184, 196, 255, 0.25);
}

.admin-header-form .form-control::placeholder {
    color: var(--bs-primary) !important;
    font-size: 12.876px;
    font-weight: 600;
}

.admin-header-icons {
    display: flex;
    align-items: center;
    gap: 40.602px;
}

.error-dots-1 {
    width: 8.584px;
    height: 8.584px;
    background-color: #BA1A1A;
    border-radius: 50%;
    position: absolute;
    top: 5%;
    right: 0%;
    border: 1.717px solid #FFF;
}

.customer-image {
    width: 41px;
    height: 41px;
    overflow: hidden;
    border-radius: 52%;
    background-color: var(--bs-secondary)
}

.customer-image img {
    width: 100%;
    height: 100%;
}

.admin-left-side-menu {
    background-color: #fff;
    padding-left: 0px;
}

.admin-left-side-menu li {
    list-style-type: none;
}

.admin-left-side-menu li a {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #5F5E62;
    padding: 25px 20px !important;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400
}

.admin-left-side-menu li img {
    width: 24px;
    height: 24px;
}

.admin-left-side-menu li a.active {
    background-color: var(--bs-secondary) !important;
    color: #000 !important;
    box-shadow: 4px 4px 20px 0px rgba(184, 196, 255, 0.25);
}


.dashbaord-home-main .courses-main h2 {
    font-size: 32px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: none;
}

.carousel-indicators {
    bottom: -190px
}

.dashbaord-vector-1 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60%;
}

.dashbaord-vector-2 {
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
}

.progress-bar {
    background-color: var(--bs-primary) !important;
}

.progress-1 {
    width: 90%;
    background-color: #FF8100 !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.progress-bg-1-light {
    background-color: #FFF6ED !important;
}

.progress-2 {
    width: 70%;
    background-color: #0177FB !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.progress-bg-2-light {
    background-color: #EFF7FF !important;
}

.progress-3 {
    width: 40%;
    background-color: #7D88FD !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.progress-bg-3-light {
    background-color: #F2F3FF !important;
}

.info-code {
    cursor: pointer;
}


@keyframes heart {
    0% {
        transform: scale(1.07);
    }

    80% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}

.form-select:focus {
    box-shadow: none;
}

.week-select {
    font-size: 12px;
    font-weight: 500;
}

.animation-main {
    box-shadow: 4px 4px 20px 0px rgba(184, 196, 255, 0.25);
}

.animation-img {
    width: 105.204px;
    height: 60.946px;
    border-radius: 10px;
}

.trophy {
    position: absolute;
    top: 26%;
    left: 20px;
    width: 30px;
    height: 30px;
}


.text-muted-light {
    color: #A6ACBE;
}

.avatar {
    width: 40px;
    height: 40px;
}

.instructor {
    width: 30px;
    height: 30px;
}

.bookmark2 {
    width: 43px;
    height: 43px;
    position: absolute;
    top: -1%;
    right: 2%;
}

.admin-featured-courses-carousel .featured-courses-img {
    height: 288px !important;
}

.top-performers-table-main thead th p {
    color: #333;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0px;
    padding-left: 10px;
}

.top-performers-table-main thead th {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 0px;
}

.top-performers-table-main tbody th {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 0px;
}

.toppers {
    width: 36px;
    height: 36px;
}

.student-details span {
    color: #7F7F7F;
    font-size: 12px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-th-width-50 {
    width: 50px;
}

.table-th-width-152 {
    width: 152px;
}

.table-th-width-158 {
    width: 158px;
}

.table-th-width-450 {
    width: 450px;
}

.table-th-width-167 {
    width: 167px;
}

.table-th-width-100 {
    width: 100px;
}

.table-th-width-350 {
    width: 350px;
}

.status-btn-1 {
    background-color: #D2E23D;
    color: #222222;
    border-radius: 20px;
    padding: 4px 12px;
    border: 0;
    font-size: 14px;
    font-weight: 400;
}

.status-btn-2 {
    background-color: #FDC93A;
    color: #222222;
    border-radius: 20px;
    padding: 4px 12px;
    border: 0;
    font-size: 14px;
    font-weight: 400;
}

.course-content p {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
}

.table-progress-loder {
    width: 30px !important;
    height: 30px !important;
}

.table-progress-loder svg {
    width: 30px !important;
    height: 30px !important;
}

input[type="checkbox"] {
    width: 24px;
    height: 24px;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    border: 2px solid var(--bs-secondary);
    border-radius: 8px !important;
}

.loder-text {
    color: #222222;
    font-size: 24px;
    font-weight: 500;
}

.border-secondary {
    border-color: var(--bs-secondary) !important;
}

.quizzes-card {
    padding-top: 75px !important;
}

.dashbaord-right-side {
    box-shadow: 0 -5px 5px -5px rgba(184, 196, 255, 0.25);
}

@media only screen and (max-width: 2560px) and (min-width: 1441px) {

    .dashbaord-right-side .right-side {
        max-width: 1130px;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 1024px) {
    .quizzes-img {
        width: 222px;
        height: 204px;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .right-side .courses-categories {
        height: 50vh;
    }

    .right-side {
        padding: 0px;
    }

    .quizzes-img {
        width: 100%;
        height: 350px;
        border-radius: 10px;
    }

    .quizzes-card {
        padding-top: 0px !important;
    }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .admin-header-icons {
        gap: 20px;
    }

    .carousel-indicators {
        bottom: -100px;
    }

    .quizzes-img {
        width: 100%;
        height: 250px;
        border-radius: 10px;
    }

    .quizzes-card {
        padding-top: 0px !important;
    }


    .top-performers-table-main tbody tr td {
        padding-left: 20px !important;
    }

    .top-performers-table-main thead tr th {
        padding-left: 20px;
    }
}

/* ==================================================== DASHBAORD PAGE ====================================================== */
/* ==================================================== CONTENT UPLOADER PAGE ====================================================== */

.icons-crile {
    width: 30px;
    height: 30px;
    background-color: var(--bs-secondary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons-crile img {
    width: 16.667px;
    height: 16.667px;
}

.progress-height01 {
    background-color: #F5F3F7;
    height: 3px;
}

.progress01 {
    background-color: #D2E23D !important;
    height: 3px;
    width: 50%;
}

.progress-height01 {
    background-color: #F5F3F7;
    height: 3px;
}

.progress02 {
    background-color: #FFA5BC !important;
    height: 3px;
    width: 40%;
}

.progress03 {
    background-color: #B8C4FF !important;
    height: 3px;
    width: 40%;
}

.progress04 {
    background-color: #FF5A59 !important;
    height: 3px;
    width: 30%;
}

.progress05 {
    background-color: #FFC775;
    height: 3px;
    width: 30%;
}


.drop-section {
    width: 100%;
    border: 1.076px dashed rgba(39, 116, 207, 0.30);
    background-color: #EEF6FF;
    border-radius: 12px;
    position: relative;
}

.drop-section div.col:first-child {
    opacity: 1;
    visibility: visible;
    transition-duration: 0.2s;
    transform: scale(1);
}

.drop-section div.col:last-child {
    font-size: 40px;
    font-weight: 700;
    color: #c0cae1;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 200px;
    height: 55px;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.6);
    transition-duration: 0.2s;
}

.drop-section .cloud-icon {
    margin-top: 25px;
    margin-bottom: 20px;
}

.drop-section button {
    color: #fff;
    background-color: #5874C6;
    border: none;
    outline: none;
    padding: 7px 20px;
    border-radius: 8px;
    margin-top: 20px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.drop-section input {
    display: none;
}

.list-section-main ::-webkit-scrollbar {
    display: none !important;
}

.list-section li {
    display: flex;
    padding-top: 4px;
    padding-bottom: 2px;
    transition-duration: 0.2s;
    border-radius: 5px;
    border: 0.538px solid #E3E3E3;
    background: #FFF;
    margin-bottom: 10px;
}

.imagesname {
    color: #0F0F0F;
    text-align: center;
    font-size: 12.914px;
    font-weight: 400;
}

.icons-circle {
    font-size: 17px;
    color: #E6E6E6;
}

.list-section li .file-progress {
    width: 100%;
    height: 5px;
    margin-top: 8px;
    border-radius: 8px;
    background-color: #4C95EB;
}

.list-section li .col svg.cross,
.list-section li .col svg.tick {
    fill: #8694d2;
    background-color: #dee6fd;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.preview-course-faq .accordion-button:not(.collapsed) {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border: 0;
}


.preview-course-faq .collapsed .badge {
    background-color: var(--bs-secondary) !important;
    color: #000 !important;
}

.rating-badge {
    background-color: #FFF0BB;
    white-space: nowrap;
}

.rating-badge svg {
    display: inline-block !important;
}

.learning-groups-images-main img {
    width: 36px;
    height: 36px;
    margin-left: -15px;
}

.summer-bootcamp-main {
    background-image: url("../assets/images/featured-details-05.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
}

.group-view-list.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: transparent !important;
    color: var(--bs-primary) !important;
    border-bottom: 1px solid var(--bs-primary);
    border-radius: 0px !important;
}

.up-image {
    height: 93px;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.border-left-btn {
    border-left: 1px solid #B8CCFF !important;
}

.social-btn button:hover {
    background-color: #B8CCFF !important;
}

.upheght {
    height: 200px;
}

.select-width {
    width: 180px;
}

.members-t-header tr th {
    background-color: transparent;
    padding-left: 20px;
}

.members-t-body tr td {
    border-bottom: 10px solid #fbf8fd !important;
    padding: 20px !important;
}

@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .list-section-main {
        height: 175px;
        overflow: auto;
    }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .barchart-progress-team {
        width: 650px;
    }

    .barchart {
        width: 500px;
    }

    .areachart {
        width: 500px;
    }
}

.form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}

.month-select-drop {
    position: absolute;
    bottom: -18px;
}

.upload-icon {
    width: 74.021px;
    height: 64.125px;
}

.course-list-menu .active {
    background-color: var(--bs-secondary);
}

/* ==================================================== CONTENT UPLOADER PAGE ====================================================== */
/* ==================================================== Gold Level PAGE ====================================================== */
.gold-level-left-list .active {
    background-color: var(--bs-secondary);
}


@media only screen and (max-width: 2560px) and (min-width: 1200px) {

    .desktop-fram {
        width: 518.53px;
        height: 397.453px;
    }

    .studio-display-front {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 494.678px;
        height: 278.321px;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 1024px) {
    .desktop-fram {
        width: 460.53px;
        height: 397.453px;
    }

    .studio-display-front {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 436.678px;
        height: 278.321px;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 576px) {
    .desktop-fram {
        width: 460.53px;
        height: 397.453px;
        margin-bottom: 50px;
    }

    .studio-display-front {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 436.678px;
        height: 278.321px;
    }
}

@media only screen and (max-width: 575px) and (min-width: 280px) {

    .desktop-fram {
        width: 311px;
        height: 334.453px;
        margin-bottom: 18px;
    }

    .studio-display-front {
        position: absolute;
        top: 7px;
        left: 12px;
        width: 284px;
        height: 233.321px;
    }

    .run-gift {
        width: 100%;
        height: 100%;
    }
}

.employee-home {
    background-color: #001854;
}

@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .dpos .team-pricing-vector-3 {
        left: -102%;
        bottom: 16%;

    }

    .dpos .team-pricing-vector-4 {
        top: 45%;
        left: 50%;
    }
}

/* ==================================================== Gold Level PAGE ====================================================== */
/* ==================================================== Inspirational Talk - Popup Start ====================================================== */
.inspirational-talk-popup .form-control,
.inspirational-talk-popup .form-select {
    background-color: var(--bs-secondary);
    padding: 10px 10px;
    resize: none;
}

.inspirational-talk-popup input::placeholder,
.inspirational-talk-popup textarea::placeholder,
.inspirational-talk-popup select::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: var(--bs-primary) !important;
}

.rows {
    height: 100px !important;
    padding: 10px;
}

.talk-gif {
    width: 192px;
    height: 251px;
}

/* ==================================================== Inspirational Talk - Popup End ====================================================== */

.left-side-menu {
    width: 105px;
    white-space: nowrap;
}

.scrolling-text-container {
    width: 115px;
    height: 25px;
    overflow: hidden;
}

.scrolling-text {
    white-space: nowrap;
    display: inline-block;
    width: 100px;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.option,
#flip {
    padding: 10px;
    color: var(--bs-primary);
    font-size: 1rem;
    cursor: pointer;
}

.option {
    padding: 10px;
    display: none;
}

#location {
    display: none;
}

.op-select {
    cursor: pointer;
}

.calendly-inline-widget {
    min-width: 300px;
    height: 700px;
}

@media only screen and (max-width: 2560px) and (min-width: 768px) {
    .home-text-slide .owl-dots {
        position: absolute !important;
        left: 50% !important;
        bottom: -140px !important;
        transform: translate(-50%, -50%) !important;
    }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .home-text-slide .owl-dots {
        position: absolute !important;
        left: 50% !important;
        bottom: -100px !important;
        transform: translate(-50%, -50%) !important;
    }
}

.home-text-slide .owl-dot span {
    width: 30px !important;
    height: 1px !important;
    background-color: var(--bs-secondary) !important;
}

.home-text-slide .owl-dots .owl-dot.active span {
    background: #fff !important;
    width: 30px !important;
    height: 1px !important;
}

.headerClassName {
    background-color: red !important;
}

.cate-teams .categories-main {
    padding-top: 0rem !important;
}

.btn-close {
    --bs-btn-close-color: #000;
    /* --bs-btn-close-bg: url("../../assets/images/clear.png"); */
    --bs-btn-close-opacity: 1;
}

.pagination ul {
    width: auto !important;
}

.pagination ul li {
    padding: 0px 0px;
}

.pagination ul li .page-link {
    padding: 10px 20px;
    color: #000;
}

.page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: none;
}

.active>.page-link,
.page-link.active {
    z-index: 3;
    color: #fff !important;
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

.pagination .page-item {
    padding: 5px;
}

.pagination .page-item .page-link {
    border: 0;
    color: #333333;
    font-size: 13px;
    font-weight: 600;
    border-radius: 50%;
    box-shadow: 0 0.125rem 0.25rem rgba(241, 241, 241, 1);
    background-color: #fff;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active>.page-link,
.page-link.active {
    color: #fff !important;
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
}

.disabled>.page-link,
.page-link.disabled {
    background-color: var(--bs-pagination-disabled-bg) !important;
}

.loader-css {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;

}


.loader-css.main {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: #dfdcfb6b;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.otpInput {
    width: 67.66px !important;
    height: 64.95px;
    margin: 0 8px;
    color: var(--bs-primary);
    font-size: 32.47px;
    font-weight: 700;
    text-align: center;
    border-radius: 9.58px;
}

.otpBlankInput {
    background-color: #fff;
    border: 0.96px solid rgba(219, 229, 255, 1);
}

.otpFillInput {
    background-color: var(--bs-secondary);
    border: 1.35px solid rgba(0, 47, 167, 0.3);
}

.otpVerifybutton {
    width: 395px;
    background: var(--bs-primary);
    border-radius: 8px;
}

/*error message */
.error {
    color: #E02E24;
    font-size: 15px;
}

@media only screen and (max-width: 1024px) and (min-width: 280px) {
    .otpInput {
        width: 40px !important;
        height: 40px;
        margin: 0 4px;
    }

    .answer-list label {
        display: block;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 1440px) {
    .no-corce-found {
        height: 500px;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 1024px) {
    .Skeleton-slider {
        top: 88%;

    }
}

.no-corce-found {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-bottom: 60px;
}

.profile-initials {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
}

.user-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    color: white;
    border-radius: 20px;
    padding: 1%;
    cursor: pointer;

}

.offcanvas .user-profile {
    display: none;
}

.dropdown-menu-home {
    position: absolute;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-menu-home button {
    padding: 5px 10px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 5px;
}

.dropdown-menu-home button:hover {
    background-color: #e0e0e0;
}

.header-bg {
    background-color: #fff !important;
}

.header-bg .nav-item .nav-link {
    color: #000;
}

.header-bg .white-logo {
    display: block !important;
}

.header-bg .home-logo {
    display: none !important;

}

.header-bg .bar svg {
    fill: #000 !important;
    width: 25px;
    height: 25px;
}

.header-bg .translate-dark-svg {
    display: block !important;
}

.header-bg .translate-white-svg {
    display: none !important;
}

.header-bg #Translate {
    color: var(--bs-primary) !important;
}

.graduation-icon {
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.graduation {
    width: 130px;
    display: flex;
    justify-content: center;
    margin: auto;
    object-fit: cover;
}

.sign-up-btn {
    background-color: #ffc107 !important;
}

.login-btn-sm {
    display: block !important;
    background-color: #D2E23D;
    border: 0;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    padding: 10px 20px;
    text-align: center;
}

.sign-up-btn-sm {
    display: block !important;
    background-color: #ffc107;
    border: 0;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    padding: 10px 20px;
    text-align: center;
}


/* // scelaton  */
@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.skeleton-card>div>div {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 100%;
    position: relative;
}

.footer-bottom {
    background-color: var(--bs-primary) !important;
    color: #fff;
    text-align: center;
    padding: 20px 20px;
}

.footer-bottom p {
    color: #fff !important;
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
}

.footer-bottom a {
    color: #fff !important;
    font-size: 16px;
    font-weight: 700;
}

#button {
    display: inline-block;
    background-color: #000;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    border: 0px;
    position: fixed;
    bottom: 70px;
    right: 12px;
    transition: background-color .3s,
        opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    #button {
        bottom: 20px;
    }
}


#button:hover {
    cursor: pointer;
    background-color: #000;
}

#button:active {
    background-color: #000;
}

#button.show {
    opacity: 1;
    visibility: visible;
}

#button img {
    width: 20px;
    height: 20px;
}

.loading div {
    background-color: #ededed;
    background: linear-gradient(100deg,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, .5) 50%,
            rgba(255, 255, 255, 0) 60%) #ededed;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
    to {
        background-position-x: -20%;
    }
}

.loading .card-body {
    background: white;
}

.pt6 {
    padding-top: 80px !important;
}

.sidebar-category-main {
    box-shadow: 4px 4px 20px 0px rgba(184, 196, 255, 0.25);
}

.sidebar-category-main .accordion-item {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.sidebar-category-main .accordion-button {
    border: 0px !important;
    background-color: var(--bs-secondary) !important;
    color: #000 !important;
    padding: 25px 15px;
    font-size: 14px;
    font-weight: 400;
}

.accordion-item-border-top {
    border-top: 1px solid var(--bs-secondary) !important;
}

.accordion-item-border-top-2 {
    border-top: 1px solid #f7f7f7 !important;
}


.sidebar-category-main .collapsed {
    background-color: #fff !important;
}

.category-drop-main .dropdown-menu {
    width: 300px;
    border: 0;
    padding: 10px;
    margin-top: 20px;
}

.category-drop-main .dropdown-menu a:hover ul {
    display: block !important;
}

.sub-category-list {
    top: -18%;
    max-width: 320px;
    width: 320px;
    position: absolute;
    left: 280px;
    border-radius: 6px !important;
    background-color: #FFF;
    overflow: hidden;
    padding: 10px;
}

.sub-category-list li a .row {
    list-style-type: none;
    padding: 17px 0px !important;
}

.category-drop-main .dropdown-toggle::after {
    display: none;
}

.category-drop-main .dropdown-item {
    padding: 15px 0px !important;
}


.dropdown-item.active,
.dropdown-item:active {
    color: #000 !important;
    text-decoration: none;
    background-color: transparent !important;
}

.dropdown-item:hover {
    background-color: var(--bs-secondary) !important;
    color: #000 !important;
    border-radius: 8px;
}


@media only screen and (max-width: 1024px) and (min-width: 280px) {
    .sidebar-category-main .accordion-button {
        font-size: 19px;
    }

    .sidebar-category-main .accordion-body p {
        font-size: 16px;
    }

    .sidebar-menu-sm button {
        font-size: 19px;
        font-weight: 500 !important;
        color: #000 !important;
        padding-top: 0px;
        padding-left: 15px;
    }
}

.sub-category-list li a:hover .row {
    background-color: var(--bs-secondary) !important;
    border-radius: 8px;
}

.black-link {
    color: black !important;
    text-decoration: none;
}

.home-slider .badge {
    text-align: start;
    text-wrap: wrap;
    line-height: 20px;
}

.one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 0px;
}

/* Gamification Flow Start */
.badge-image {
    width: 90px;
    height: 90px;
    margin: auto;
}

.badge-image img {
    width: 100%;
    height: 100%;
}

.badge-title {
    font-size: 14px;
    font-weight: 400;
    color: #000C2A;
    margin-bottom: 0px;
    text-align: center;
}

.badge-subtitle {
    font-size: 11px;
    font-weight: 400;
    color: #A7AAC1;
    margin-bottom: 0px;
    text-align: center;
}

.border-right-badge {
    border-right: 1px solid #DDE1FF;
}

.view-all-bottom-button {
    border-top: 1px solid #b8ccff;
}

.view-all-bottom-button a {
    font-size: 16px;
    font-weight: 600;
    color: #93a6ff;
    text-align: center;
    padding-top: 15px;
}

.badge-modal-header .modal-title {
    font-size: 32px;
    font-weight: 600;
    color: #000c2a;
}

.badge-modal-header p {
    font-size: 14px;
    font-weight: 400;
    color: #5f5e62;
}

/* All Badges Modal start */
.badges-unlocked-btn {
    background-color: var(--bs-secondary);
    color: #4c7fff;
    font-size: 16px;
    font-weight: 600;
}

.course-badges {
    font-size: 18px;
    font-weight: 600;
    color: #5f5e62;
}

.unlock-more-btn {
    color: var(--bs-primary);
    font-size: 16px;
    font-weight: 600;
    border: 1px solid var(--bs-primary);
}

.all-badges-modal-main .badge-image {
    width: 130px;
    height: 130px;
}

.badge-list-mail2 .border-right-badge:nth-child(4) {
    border-right: 0px;
}

.badge-list-mail2 .border-right-badge:nth-child(8) {
    border-right: 0px;
}

.badge-list-mail .border-right-badge:nth-child(5) {
    border-right: 0px;
}

.badge-list-mail .border-right-badge:nth-child(9) {
    border-right: 0px;
}


@media only screen and (max-width: 768px) and (min-width: 576px) {
    .badge-list-mail .border-right-badge:nth-child(3) {
        border-right: 0px;
    }

    .badge-list-mail .border-right-badge:nth-child(5) {
        border-right: 0px;
    }

    .badge-list-mail .border-right-badge:nth-child(7) {
        border-right: 0px;
    }

    .badge-list-mail .border-right-badge:nth-child(9) {
        border-right: 0px;
    }

    .badge-list-mail2 .border-right-badge:nth-child(2) {
        border-right: 0px;
    }
}

@media only screen and (max-width: 575px) and (min-width: 280px) {
    .badge-list-mail .border-right-badge {
        border-right: 0px;
    }

    .badge-list-mail2 .border-right-badge:nth-child(2) {
        border-right: 0px;
    }

    .badge-list-mail2 .border-right-badge:nth-child(6) {
        border-right: 0px;
    }
}

/* All Badges Modal end */

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .border-sm-right-0 {
        border-right: 0px;
    }

    .animation-img {
        width: 83.204px;
        height: 48.946px;
        border-radius: 10px;
    }

    .answer-list {
        padding: 0px !important;
    }
}

.image-stack img {
    width: 30px;
    height: 30px;
}

/* Gamification Flow End */

/* new quiz flow Start */
.base-timer {
    position: relative;
    width: 100px;
    height: 100px;
}


.base-timer__circle {
    fill: none;
    stroke: none;
}

.base-timer__path-elapsed {
    stroke-width: 7px;
    stroke: var(--bs-primary);
}

.base-timer__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
}

.base-timer__path-remaining.green {
    color: #1B143F;
}

.base-timer__path-remaining.orange {
    color: orange;
}

.base-timer__path-remaining.red {
    color: red;
}

.base-timer__label {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

/* new quiz flow End */
/* custom radios check icon start */
.custom-radios div {
    display: inline-block;
}

.custom-radios input[type=radio] {
    display: none;
}

.custom-radios input[type=radio]+label {
    color: #333;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

.custom-radios input[type=radio]+label span {
    display: inline-block;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--bs-secondary);
}

.check-icn {
    width: 15px;
    height: 15px;
}


.custom-radios input[type=radio]+label span svg {
    opacity: 0;
}

.custom-radios input[type=radio]:checked+label span svg {
    opacity: 1;
}

.selected-answer {
    border: 1px solid var(--bs-primary) !important;
    border-radius: 8px;
    color: var(--bs-primary) !important;
    background-color: #EDF2FF;
}

.selected-answer-red {
    border: 1px solid #DB0544 !important;
    border-radius: 8px;
    color: #DB0544 !important;
    background-color: #FCE8EE;
}

.selected-answer-red .check-icon {
    display: none;
}

.selected-answer-green .check-icon {
    display: block;
    opacity: 1 !important;
}

.selected-answer-green .close-icon {
    display: none;
}

.selected-answer .close-icon {
    display: none;
}

.selected-answer-green {
    border: 1px solid #2EC04F !important;
    border-radius: 8px;
    color: #2EC04F !important;
    background-color: #ECFAEF;
}

.custom-radios .label-border {
    border: 1px solid var(--bs-secondary);
    border-radius: 8px;
}

.selected-answer span {
    border: 1px solid var(--bs-primary) !important;
}

.selected-answer-green span {
    border: 1px solid #2EC04F !important;
}

.selected-answer-red span {
    border: 1px solid #DB0544 !important;
}

.selected-answer-red span svg {
    fill: #DB0544 !important;
}

.selected-answer-green span svg {
    fill: #2EC04F !important;
    opacity: 1 !important;
}

.border-primary-1 {
    border: 1px solid var(--bs-secondary);
    border-radius: 8px;
}

/* custom radios check icon end */
/* progress start */

.progress-bar__wrapper {
    position: relative;
}

.progress-bar__value {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

progress {
    width: 100%;
    height: 7px;
    border-radius: 50px;
    background-color: var(--bs-secondary);
    transition: width 300ms ease;
}



progress[value]::-webkit-progress-bar {
    width: 100%;
    height: 7px;
    border-radius: 50px;
    background-color: var(--bs-secondary);
    transition: width 300ms ease;
}

progress[value]::-webkit-progress-value {
    width: 0;
    border-radius: 50px;
    background-color: var(--bs-primary);
    transition: width 300ms ease;
}

/* progress end */

.cardslide-main {
    height: 630px;
}

/*  stack of cards with shuffle animation start */

.cardslide {
    width: 100%;
    height: 630px;
    max-width: 100%;
    position: absolute;
    box-shadow: rgb(204, 219, 232) -3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) 3px -3px 6px 1px inset;
}

@media only screen and (max-width: 767px) and (min-width: 200px) {

    .cardslide {
        max-width: 85%;
    }

    .cardslide-main {
        height: 740px;
    }
}

.card-1 {
    right: calc((var(--xaxis-start-point) - (100% / 2)) + var(--offset-amount) * 7);
    z-index: var(--num-cards);
    top: calc(var(--offset-amount) * 4);
    display: block !important;
}

.card-2 {
    right: calc((var(--xaxis-start-point) - (100% / 2)) + var(--offset-amount) * 5);
    top: calc(var(--offset-amount) * 2);
    z-index: calc(var(--num-cards) - 1);
    display: block !important;
}

.card-3 {
    right: calc((var(--xaxis-start-point) - (100% / 2)) + var(--offset-amount) * 3);
    top: calc(var(--offset-amount) * 0);
    z-index: calc(var(--num-cards) - 2);
    display: block !important;
}

.shuffle {
    animation-name: move-right, send-to-back, move-left;
    animation-duration: 500ms, 10ms, 500ms;
    animation-delay: 0ms, 500ms, 510ms;
    animation-timing-function: linear, linear, linear;
}

@keyframes move-right {
    from {
        z-index: var(--num-cards);
        right: calc(var(--xaxis-start-point) - (var(--card-width) / 2));
        transform: translateX(0) rotate(0);
    }

    to {
        z-index: var(--num-cards);
        right: calc(var(--xaxis-start-point) - (var(--card-width) / 2));
        transform: translateX(-180px) rotate(-10deg);
    }
}

@keyframes move-left {
    from {
        z-index: 1;
        right: calc(var(--xaxis-start-point) - (var(--card-width) / 2));
        top: var(--offset-amount);
        transform: translateX(-180px) rotate(-10deg);
    }

    to {
        z-index: 1;
        right: calc((var(--xaxis-start-point) - (var(--card-width) / 2)) + var(--offset-amount) * 4);
        top: calc(var(--offset-amount) * 5);
        transform: translateX(0) rotate(0);
    }
}

/* Bulk flow start */
.border-primary-btn {
    border: 1px solid var(--bs-primary);
    color: var(--bs-primary);
    font-size: 16px;
    font-weight: 600;
    background-color: #fff;
    padding: 13px 20px;
    border-radius: 8px;
}

.btn-primary2 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    background-color: var(--bs-primary);
    padding: 13px 20px;
    border-radius: 8px;
    border: 0px;
}


.step-container {
    position: relative;
    text-align: center;
    transform: translateY(-55%);
}

.step-container div {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #A1AEBE;
    line-height: 30px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.active-step {
    color: var(--bs-primary);
    border: 2px solid var(--bs-primary) !important;
}

.step-text-list .active-step {
    border: 0px !important;
}


.step-line {
    position: absolute;
    top: 16px;
    left: 50px;
    width: calc(100% - 100px);
    height: 2px;
    background-color: var(--bs-primary);
    z-index: -1;
}

#multi-step-form {
    overflow-x: hidden;
}

/* Bulk flow end */


/* Select a file or drag and drop here start */
.pf-v5-c-multiple-file-upload__main {
    border-radius: 10px;
    padding-block-start: 60px !important;
    padding-block-end: 60px !important;
    padding-inline-start: 50px !important;
    padding-inline-end: 40px !important;
}

.pf-m-horizontal .pf-v5-c-multiple-file-upload__main {
    padding-block-start: 25px !important;
    padding-block-end: 10px !important;
    padding-inline-start: 15px !important;
    padding-inline-end: 15px !important;
}


.pf-v5-c-multiple-file-upload__title-text-separator {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    margin-block-start: 15px !important;
}

.pf-v5-c-button.pf-m-secondary {
    --pf-v5-c-button--after--BorderColor: var(--bs-primary) !important;
    color: var(--bs-primary) !important;
    background-color: #fff !important;
    padding: 12px 24px 12px 24px;
    --pf-v5-global--BorderRadius--sm: 8px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.pf-v5-c-multiple-file-upload__title-icon {
    margin-bottom: 20px !important;
    margin-right: 10px !important;
}

.pf-v5-c-multiple-file-upload__status-item-progress-text {
    font-size: 12px !important;
    color: #000000 !important;
}

.pf-v5-c-progress__measure {
    font-size: 12px !important;
    color: #000000 !important;
    padding-top: 5px !important;
}

.pf-v5-c-multiple-file-upload__status-item-progress-size {
    font-size: 12px !important;
}

.pf-v5-c-progress__indicator {
    height: 5px !important;
    background-color: #6C88FF !important;
    border-radius: 10px;
    overflow: hidden;
}

.pf-v5-c-progress__bar {
    border-radius: 10px !important;
    --pf-v5-global--BorderRadius--sm: 8px !important;
}

.pf-v5-c-progress__bar::before {
    height: 5px !important;
    content: "";
    background-color: #e6e6e6 !important;
    opacity: 1 !important;
    border-radius: 10px;
    overflow: hidden;
}

.pf-v5-c-multiple-file-upload__status-item {
    padding-block-start: 0px !important;
    padding-block-end: 0px !important;
    border-block-end: 0px !important;
    align-items: center;
}

.xls-png {
    width: 17px;
    height: 24px;
    margin-right: 5px;
}

.pf-m-plain {
    padding-bottom: 32px !important;
}

.button-footer {
    background-color: #fbfdfe !important;
}

.btn-upload {
    border: 0px;
    padding: 12px 24px 12px 24px;
    background-color: var(--bs-primary);
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.btn-cancel {
    border: 0px;
    padding: 12px 24px 12px 24px;
    background-color: #fff;
    border-radius: 8px;
    color: #000;
    font-weight: 400;
    font-size: 14px;
}

.pf-v5-c-multiple-file-upload__status-item-progress-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media only screen and (max-width: 767px) and (min-width: 200px) {

    .pf-v5-c-multiple-file-upload__main {
        border-radius: 10px;
        padding-block-start: 20px !important;
        padding-block-end: 20px !important;
        padding-inline-start: 10px !important;
        padding-inline-end: 10px !important;
    }

    .pf-m-horizontal .pf-v5-c-multiple-file-upload__main {
        display: flex;
        gap: 16px !important;
        flex-direction: column;
    }

    .badge-black-2 {
        text-wrap: wrap;
        text-align: start;
    }
}

/* Select a file or drag and drop here end */

/* Preview table start */
.preview-table {
    border-top: transparent;
    border-left: transparent;
}

.preview-table tbody tr:last-child {
    border-bottom: transparent;
}

.preview-table tbody tr td:last-child {
    border-right: transparent;
}

.preview-table thead tr th:last-child {
    border-right: transparent;
}

.preview-table thead tr th {
    font-size: 16px;
    font-weight: 600;
    color: #222222;
    padding-left: 15px;
    padding: 20px 8px;
    background-color: #FCFCFC;
    text-wrap: nowrap;
}

.preview-table tbody tr td {
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    padding: 20px 8px;
    background-color: #FCFCFC;
    text-wrap: nowrap;
}

.table-pagination .page-item .page-link {
    box-shadow: none !important;
}

.table-pagination .page-item .page-link {
    box-shadow: none !important;
}

.table-pagination .active .page-link {
    color: var(--bs-primary) !important;
    background-color: transparent !important;
}

.select-number {
    width: 65px;
    padding-right: 30px !important;
    padding-left: 5px !important;
    margin-left: 10px !important;
    border: 0px;
}

.w-50 {
    width: 50px !important;
}

.table-pagination .page-item .page-link {
    font-size: 14px;
    font-weight: 500;
    color: #242731;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: var(--bs-primary) !important;
    stroke-width: 5px;
}

.circularprogressbar2 svg .CircularProgressbar-path {
    stroke: var(--bs-primary) !important;
    stroke-width: 8px;
}



.CircularProgressbar .CircularProgressbar-trail {
    stroke: #B8CCFF !important;
    stroke-width: 5px;
}

.circularprogressbar2 svg .CircularProgressbar-trail {
    stroke: #B8CCFF !important;
    stroke-width: 3px;
}

.CircularProgressbar-text {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: var(--bs-primary) !important;
    fill: var(--bs-primary) !important;
}

/* Preview table end */

.animation-1 {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: var(--bs-primary);
    position: absolute;
    animation: scaling 2s cubic-bezier(0.12, 0.7, 0.74, 0.71) infinite;
}

@keyframes scaling {
    0% {
        transform: scale(1);
        background-color: var(--bs-primary);
    }

    100% {
        transform: scale(4);
        background-color: rgba(0, 47, 167, 0);
    }
}

.circle1 {
    animation-delay: 0s;
}

.circle2 {
    animation-delay: 1s;
}

.circle3 {
    animation-delay: 2s;
}

.main-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
    width: 200px;
    height: 200px;
}

.main-animation::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--bs-primary);
    background-image: url("../assets/images/check.png");
    background-repeat: no-repeat;
    background-position: center;
}

.active-step.confirm {
    background-color: var(--bs-primary);
    background-image: url("../assets/images/check.png");
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.active-step.confirm span {
    display: none !important;
}

.ps-15 {
    padding-left: 2.2rem !important;
}

/* Assign Course Start */
.select-course h2 {
    text-align: start;
    font-size: 24px;
    font-weight: 500;
    color: #000C2A;
}

.select-course p {
    text-align: start;
    font-size: 16px;
    font-weight: 400;
    color: #00237D;
    margin-bottom: 5px;
}

.select-course-row .col-9.active .card {
    background-color: var(--bs-secondary) !important;
}

.form-main .date-select {
    color: #000C2A !important;
}

.employees-selection {
    background-color: var(--bs-secondary) !important;
}

/* Assign Course end */

.alert-success-bg {
    background-color: #D8FADE !important;
}

.success-text {
    color: #344A37 !important;
}

/* Normal state of the button */
.btn-upload {
    background-color: var(--bs-primary);
    color: #ffffff;
    border: none;
}

/* Disabled state of the button */
.btn-upload:disabled {
    background-color: var(--bs-primary);
    color: #ffffff;
    opacity: 0.5;

}

.red-background {
    color: #f80f0f !important;
}

.table-pagination .page-item .page-link svg {
    width: 30px;
    height: 30px;
}

.table-pagination .page-link {
    text-wrap: nowrap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input.form-control::-webkit-outer-spin-button,
input.form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.form-control[type=number] {
    -moz-appearance: textfield;
}

.detele-width {
    width: 130px;
    padding: 0px 40px !important;
}

/* CSS */
@media only screen and (max-width: 767px) and (min-width: 200px) {

    .scrolling-text-container {
        width: 100%;
    }

    .sidbar-list-comman.offcanvas-body ul {
        width: 100%;
    }

}

/* Compliance section start */
.text-light-1 {
    color: #343434 !important;
}

.text-light-2 {
    color: #949494 !important;
}

.text-light-3 {
    color: #32D16D !important;
}

.text-light-4 {
    color: #F2383A !important;
}

.naturenova {
    color: #A2DF53 !important;
}

.compliance-score-progress {
    width: 100%;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.compliance-score-progress p {
    height: 20px;
}

.very-low {
    background-color: #E25444;
}

.low {
    background-color: #FF9045;
}

.medium {
    background-color: #FFDD80;
}

.high {
    background-color: #A2DF53;
}

.very-high {
    background-color: #3FC867;
}

.point-list li {
    display: flex;
    align-items: center;
    gap: 10px;
}

.point-list li span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #0A0A0A;
}

.compliance-score-dashboard thead tr th {
    padding: 20px;
    font-size: 14px;
    font-weight: 700;
}

.compliance-score-dashboard tbody tr td {
    padding: 18px;
    font-size: 14px;
    font-weight: 400;
}

.compliance-score-dashboard tbody tr td {
    border-bottom: 1px solid var(--bs-secondary) !important;
}

.compliance-score-dashboard tbody tr:last-child td {
    border-bottom: none !important;
}

.profile-image {
    width: 158px;
    height: 158px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--bs-primary);
    display: flex;
    justify-content: center;
    font-size: 50px;
}

.profile-image img {
    object-fit: cover;
}

.add-button {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-color: var(--bs-primary);
    color: #fff;
    border: 0px;
}

.add-button img {
    width: 20px !important;
    height: 20px !important;
}

.swal2-confirm.btn-success,
.swal2-cancel.btn-danger {
    color: #fff !important;
    margin-left: 10px;
    padding: 10px !important;
}

::-webkit-file-upload-button {
    display: none;
}

/* Compliance section end */

.assign-course-title {
    font-size: 32px;
    font-weight: 600;
    color: #000C2A;
}

.select-person-list .form-check-input {
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
}

.select-person-list label {
    margin: 0px;
    font-size: 18px !important;
    font-weight: 500 !important;
    padding: 0px !important;
}

.circle-arrow {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

/* Start */
/* .top-title-header{
    padding-top: 20px;
} */

.top-title-header h1 {
    color: #0f172a;
    font-size: 30px;
    font-weight: 700;
    list-style-type: auto;
    margin-bottom: 0px;
}

.top-title-header p {
    color: #475569 !important;
    font-size: 20px;
    font-weight: 400;
}

.main-body h2 {
    color: #0f172a;
    font-size: 24px;
    font-weight: 700;
    list-style-type: auto;
    margin-bottom: 0px;
}

.main-body p {
    color: #475569 !important;
    font-size: 18px;
    font-weight: 400;
}

.main-body ul li {
    margin-bottom: 15px;
}

/* End */
/* Ai Step Page Start */
.ai-step-title {
    color: #000C2A;
    font-weight: 600;
    font-size: 48px;
    text-align: center;
    padding: 70px 0px;
    max-width: 459px;
    width: 100%;
    margin: auto;
}

.ai-step-imge {
    margin-bottom: 80px;
}

.ai-step-imge img {
    width: 90%;
    display: flex;
    margin: auto;
}


.ai-step-title-2 {
    font-weight: 500;
    font-size: 28px;
    color: #000C2A;
    line-height: 38px;
}

.ai-step-description {
    font-weight: 300;
    font-size: 16px;
    color: #3a425b;
}

.ai-textarea {
    background-color: var(--bs-secondary);
}

.ai-textarea::placeholder {
    color: #4266c2;
}

.ai-textarea:focus {
    background-color: var(--bs-secondary);
}

.fail-text {
    font-size: 56px;
}

@media only screen and (max-width: 767px) and (min-width: 200px) {
    .assign-course-title {
        font-size: 20px;
    }

    .w-sm-100 {
        width: 100% !important;
    }

    .ai-step-title {
        font-size: 30px;
        padding-top: 0px;
        padding-bottom: 30px;
    }

    .ai-step-imge {
        margin-bottom: 20px;
    }

    .ai-step-title-2 {
        font-size: 23px;
    }

    .ai-step-imge img {
        width: 100%;
    }

    .fail-text {
        font-size: 35px;
    }
}

/* Ai Step Page End */

.selected-answer-red .horse-img {
    position: absolute;
    z-index: 1;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 350px;
    height: 350px;
    /* background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); */
}

.selected-answer-green .horse-img {
    position: absolute;
    z-index: 1;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 350px;
    height: 350px;
    /* background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); */
}

.selected-answer-green .righthorse,
.selected-answer-red .wronghorse {
    display: block !important;
}

.gpt-details li {
    list-style-type: disc !important;
}

.horse-gif {
    position: absolute;
    bottom: 34%;
    right: -26%;
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #FFFFFF 44.61%, rgba(255, 255, 255, 0.840675) 66.06%, rgba(255, 255, 255, 0.649485) 71.79%, rgba(255, 255, 255, 0.437051) 81.77%, rgba(255, 255, 255, 0) 100%);
    /* transition: transform 0.1s; */
    /* transform: scale(2); */
    width: 80%;
    height: 100%;
}

.horse-gif img {
    width: 100% !important;
}

.tooltip>div.tooltip-inner {
    background-color: #000c2a !important;
    color: #FFFFFF !important;
    font-size: 12px;
    font-weight: 700;
}

.wait-title {
    max-width: 889px;
    width: 100%;
    text-align: center;
    margin: auto;
    color: #000C2A;
    font-size: 24px;
    font-weight: 500;
    padding-top: 50px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 2560px) and (min-width: 1400px) {

    .loading-imag img {
        width: 60%;
        display: flex;
        margin: auto;
    }
}

.loading-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -15%);
}

.loading-text h2 {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #000C2A;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.loading-text img {
    /* max-width: 600px; */
    width: 280px;
    display: flex;
    margin: auto;
    justify-content: center;
    margin-bottom: 20px !important;
}

.loading-text h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #F36A8D;
}

@media only screen and (max-width: 1300px) and (min-width: 992px) {

    .loading-imag {
        width: 100%;
        display: flex;
        margin: auto;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {

    .loading-imag img {
        width: 100%;
        display: flex;
        margin: auto;
    }

    .loading-imag {
        width: 100%;
        display: flex;
        margin: auto;
    }

    .wait-title {
        font-size: 20px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 200px) {

    .loading-imag img {
        width: 100%;
        display: flex;
        margin: auto;
        margin-bottom: 10px !important;
    }

    .loading-imag {
        width: 100%;
        display: flex;
        margin: auto;
    }

    .wait-title {
        font-size: 16px;
    }

    .loading-text h2 {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .loading-text h3 {
        font-size: 12px;
    }

    .loading-text {
        transform: translate(-40%, -45%);
    }

    .btn-light-warning {
        font-size: 16px;
    }
}

.bookmark-btn {
    color: #fff;
    padding: 6px 15px;
    font-size: 14px;
}

.star-svg {
    width: 45px;
    height: 45px;
}

.style-module_emptyIcons__Bg-FZ.empty-icons {
    color: var(--bs-secondary) !important;
}

.style-module_fillIcons__6---A.filled-icons {
    color: #FFD029 !important;
}

.feedback-btn {
    padding-left: 4rem;
    padding-right: 4rem;
}

/* Manage Family Settings page design start */
.kids-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.text-black-300 {
    color: #323233;
}

.manage-family label {
    color: #000C2A;
    font-size: 16px;
    font-weight: 400;
}

.manage-family .form-control:focus,
.manage-family .form-control::placeholder,
.manage-family input {
    background-color: var(--bs-secondary);
    color: #6e8ad3;
    font-size: 16px;
    font-weight: 400;
    border-radius: 5px;
    box-shadow: none;
    border: 0px;
    height: 46px;
}

.manage-family-checkbox .form-check-input:checked {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

.manage-family-checkbox .form-check-input:focus,
.manage-family-checkbox input {
    border: 1px solid #B3B3B3;
    border-radius: 5px !important;
    width: 20px;
    height: 20px;
}

.manage-family-checkbox label {
    color: #232323;
    font-size: 16px;
    font-weight: 400;
    margin-top: 3px;
    margin-left: 5px;
    cursor: pointer;
}

.add-kids-btn {
    padding: 15px 28px 15px 28px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    border-radius: 8px;
    background-color: var(--bs-primary);
    border: 0px;
}

.padding-bottom-70 {
    padding-bottom: 70px;
}

/* Manage Family Settings page design end */
/* Business user start */
.welcome-title {
    color: #000C2A;
    font-size: 48px;
    font-weight: 400;
    max-width: 410px;
    width: 100%;
    margin-bottom: 20px;
}

.welcome-description {
    color: #000C2A;
    font-size: 16px;
    font-weight: 400;
    max-width: 470px;
    width: 100%;
    margin-bottom: 30px !important;
}

.max-width-100 {
    max-width: 100% !important;
}

.payment-flow-section {
    padding-top: 50px;
    padding-bottom: 30px;
}

.payment-flow-section .plan-description {
    max-width: 960px;
    margin-bottom: 51px !important;
}

.plans-card .card {
    padding: 50px 24px;
}


.plans-card :nth-child(1) .card {
    background-color: #B8CCFF;
}

.plans-card :nth-child(2) .card {
    background-color: var(--bs-primary);
}

.plans-card :nth-child(3) .card {
    background-color: #000C2A;
}

.plans-card .card h3 {
    color: #21272A;
    font-size: 32px;
    font-weight: 700;
    max-width: 200px;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 10px;
}

.plans-card .card p {
    color: #21272A;
    font-size: 18px;
    font-weight: 400;
    max-width: 350px;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 60px;
    height: 108px;
}

.plans-card .card h2 {
    color: #21272A;
    font-size: 54px;
    font-weight: 700;
    max-width: 350px;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 60px;
}

.plans-card .card button,
.plans-card .card a {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 20px 32px;
    background-color: var(--bs-primary);
    border-radius: 8px;
    margin: auto;
    display: inline-flex;
    border: 0px;
}

.plans-card :nth-child(2) .card p,
.plans-card :nth-child(3) .card p,
.plans-card :nth-child(3) .card h3,
.plans-card :nth-child(2) .card h3 {
    color: #fff !important;
}

.plans-card :nth-child(2) .card h2,
.plans-card :nth-child(3) .card h2 {
    color: #fff !important;
    margin-bottom: 5px !important;
}

.plans-card :nth-child(2) .card .employee,
.plans-card :nth-child(3) .card .employee {
    color: #fff !important;
    font-size: 14px;
    font-weight: 400;
    height: auto;
}

.plans-card :nth-child(2) .card button,
.plans-card :nth-child(2) .card a,
.plans-card :nth-child(3) .card a,
.plans-card :nth-child(3) .card button {
    background-color: #FDC93A !important;
    color: #20242C !important;
}

.support-pricing-plan h3 {
    margin-bottom: 32px !important;
}

.plan-details-list {
    padding: 0px;
    max-width: 279px;
    width: 100%;
    margin-bottom: 40px;
}

.plan-details-list li {
    list-style-type: none;
    margin: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.support-pricing-plan :nth-child(2) .card ul li,
.support-pricing-plan :nth-child(3) .card ul li {
    color: #fff;
}

.shared-courses-cards .card {
    box-shadow: 4px 4px 20px 0px #B8C4FF40;
}

.shared-courses-cards .card .card-body,
.shared-courses-cards .card .card-footer {
    padding: 25px;
}

.shared-courses-cards .card .card-body h2 {
    font-size: 24px;
    font-weight: 500;
    color: #000C2A;
}

.shared-courses-cards .card .card-body p {
    font-size: 14px;
    font-weight: 400;
    color: #5F5E62;
    margin-bottom: 0px;
}

.shared-courses-cards .card .card-footer p,
.shared-courses-cards .card .card-footer h2 {
    font-size: 28px;
    font-weight: 500;
    color: #000C2A;
    margin-bottom: 0px;
}

.shared-courses-cards .card .card-footer button {
    font-size: 16px;
    font-weight: 600;
    color: #00237D;
    background-color: #B8CCFF;
    text-align: center;
    border-radius: 5px;
    padding: 13px;
}

.shared-courses-cards .card .card-footer .button-added {
    background-color: #002FA7 !important;
    color: #fff !important;
}

.shared-courses-cards .card-top-img {
    height: 200px;
}

.quill_checkmark {
    width: 25px !important;
    height: 25px;
}

.learning-trails-cards .card {
    padding: 40px;
    border: 1px solid var(--bs-secondary);
}

.learning-trails-cards .card .card-footer p {
    font-size: 20px;
}

.learning-trails-cards .card .card-body h2 {
    font-size: 20px;
    font-weight: 500;
    color: var(--bs-primary);
    margin-bottom: 20px;
}

.learning-trails-cards .card .card-body p {
    margin-bottom: 20px;
}

.owl-stage-outer .owl-stage {
    display: flex
}

.owl-stage-outer .owl-stage .owl-item .item {
    height: 100%
}

.learning-trails-cards .owl-nav.disabled+.owl-dots {
    margin-top: 0px !important;
}

.payment-information-card h2 {
    font-size: 36px;
    font-weight: 400;
    color: #000C2A;
    margin-bottom: 18px;
}

.payment-information-card p {
    font-size: 16px;
    font-weight: 400;
    color: #000C2A;
}

.payment-information-card .card {
    box-shadow: 4px 4px 10px 0px #B8C4FF0D;
    border-radius: 5px;
    border: 0px;
    margin-top: 32px;
}

.payment-information-card .card-body h5 {
    font-size: 18px;
    font-weight: 600;
    color: #000C2A;
}

.payment-information-card .card-body p {
    font-size: 18px;
    font-weight: 400;
    color: #000C2A;
}

.payment-information-card .card-body span {
    font-size: 24px;
    font-weight: 500;
    color: #000000;
}

.payment-information-card .card .card-body h4 {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0px;
}

.payment-information-card .card .card-body h6 {
    font-size: 16px;
    font-weight: 500;
    color: #000C2A;
    margin-bottom: 0px;
}

.input-radio-box-title {
    font-weight: 400;
    font-size: 18px;
    color: #21272A;
}

.input-radio-box-title input {
    border-color: var(--bs-secondary);
    background-color: var(--bs-secondary);
}

.shared-courses-cards .input-box,
.payment-information-card .input-box {
    width: 100px;
    height: 46px;
    padding: 10px;
    border-radius: 5px;
    color: #000C2A;
    font-size: 16px;
    font-weight: 400;
    border: 0px;
    background-color: var(--bs-secondary);
    text-align: center;
}

.tailored-mandatory-card {
    background-color: #000C2A;
    color: #fff;
}

.tailored-mandatory-card h3 {
    color: #FBF8FD;
    font-size: 20px;
    font-weight: 500;
}

.tailored-mandatory-card p {
    color: #FBF8FD !important;
}

.tailored-mandatory-card .learn-more-text {
    color: #FDC93A !important;
    margin-bottom: 5px;
}

.tailored-mandatory-card span {
    color: #FBF8FD !important;
}

.tailored-mandatory-card .toggle__label {
    background-color: #FDC93A;
}

.tailored-mandatory-card .dark-mode .toggle__label {
    background-color: var(--bs-secondary) !important;
}

.toggle__input:checked+.toggle__label::after {
    background-color: #000C2A !important;
}

.debit-card-form input::placeholder {
    color: var(--bs-primary) !important;
}

.summary-details-list .border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-secondary) !important;
}

.summary-details-list .total span {
    font-size: 40px;
    font-weight: 600;
}

.tailored-mandatory-courses .modal-title {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
}

.tailored-mandatory-courses p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.tailored-mandatory-courses h6 {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
}

.tailored-mandatory-courses ul {
    padding-left: 20px;
    padding-top: 10px;
}

.tailored-mandatory-courses ul li {
    list-style-type: disc;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.tailored-mandatory-courses .modal-header {
    padding: 50px 40px 0px 40px;
}

.tailored-mandatory-courses .modal-body {
    padding: 20px 40px 0px 40px;
}

.tailored-mandatory-courses .modal-footer {
    padding: 20px 40px 40px 40px;
}

.subscription-setting-text {
    font-size: 16px;
    font-weight: 400;
    color: var(--bs-primary);
}

.tailored-mandatory-courses .modal-content {
    background-color: #FBF8FD !important;
    border-radius: 16px;
    backdrop-filter: blur(8.5px);
    box-shadow: 5px 4px 45.6px 0px #0937550F;
}

.settings-tabs .nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
    width: auto !important;
    text-wrap: nowrap;
}

.settings-tabs .nav-justified .nav-item,
.settings-tabs .nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 0;
}

.teblas-tabbar.settings-tabs ul {
    border-bottom: 1px solid #F4F5F7 !important;
}

.summary-details-list.p-4 {
    padding: 30px !important;
}

.mastercard-number {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #8D99B8 !important;
    margin: 0px;
}

.cancel-subscription {
    padding: 40px;
    border-radius: 8px;
    border: 1px solid #FBF8FD;
}

.cancel-subscription .modal-title {
    font-size: 22px;
    font-weight: 600;
    color: #54595E;
    margin-bottom: 16px;
}

.cancel-subscription-description {
    max-width: 510px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin: auto;
}

.cancel-subscription-details p {
    color: #4F4F4F;
    font-weight: 500;
    font-size: 14px;
}

.cancel-subscription-details {
    padding: 40px;
}

.cancel-subscription-details label {
    margin-bottom: 50px !important;
}

/* Business user end */
/* Individual user start */
.free-trials-cards .card {
    border-radius: 5px;
    border: 0px;
    box-shadow: 4px 4px 20px 0px #B8C4FF40;
    overflow: hidden;
}

.free-trials-cards .card-body,
.free-trials-cards .card-footer {
    padding: 25px;
}

.free-trials-cards h3 {
    font-weight: 500;
    font-size: 24px;
    color: #000C2A;
}

.free-trials-cards button {
    background-color: #B8CCFF;
    color: #00237D;
    font-size: 16px;
}

.available-trails {
    padding-top: 80px;
}

.available-trails-cards .card {
    box-shadow: 4px 4px 20px 0px #B8C4FF40;
    border-radius: 5px;
    border: 1px solid var(--bs-secondary);
    height: 100%;
}

.available-trails-cards .card {
    padding: 24px 40px;
}


.available-trails-cards .card .card-body h5 {
    font-size: 20px;
    font-weight: 500;
    color: var(--bs-primary);
    margin-bottom: 10px;
}

.available-trails-cards .card .card-body p {
    font-size: 14px;
    font-weight: 400;
    color: #5F5E62;
    margin-bottom: 0px;
}

.available-trails-cards .form-check-input {
    background-color: var(--bs-secondary);
    border: 0px;
}

.available-trails-cards .card.active {
    border-color: var(--bs-primary);
    background-color: var(--bs-secondary);
}

.free-trials-cards p {
    font-size: 14px;
    font-weight: 400;
    color: #5F5E62;
}

.free-trials-cards img {
    height: 170px;
}

.individualized-courses .card .card-body h5 {
    font-size: 24px;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 15px;
}

.individualized-courses .card {
    padding: 24px 15px;
}

.price-quantity .quantity {
    width: 30px;
    height: 52px;
    border-radius: 4px;
    background-color: #DBE5FF;
}

/* Individual user end */

/* Family start */
.family-plan-details {
    padding-left: 20px;
}

.family-plan-details li {
    font-size: 14px;
    font-weight: 400;
    color: #000C2A;
    list-style-type: disc;
    margin-bottom: 5px;
}

.popover {
    --bs-popover-bg: #001954;
}

.tooltip-table tr th,
.tooltip-table tr td {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
}

.tooltip-table .row {
    border-color: #24396c !important;
    background-color: #001954 !important;
}

.tooltip-table .col-6 {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    border-color: #24396c !important;
    padding: 10px;
}

.tooltip-table .row:last-child {
    border: 0px !important;
}

.tooltip-text {
    color: #001A59;
}

/* Family end */



/* stripe redirect */

._failed {
    border-bottom: solid 4px red !important;
}

._failed i {
    color: red !important;
}

._success {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid 4px #28a745;
}

._success i {
    font-size: 55px;
    color: #28a745;
}

._success h2 {
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}

._success p {
    margin-bottom: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
}



@media only screen and (max-width: 991px) and (min-width: 768px) {
    .welcome-title {
        font-size: 30px;
    }

    .available-trails-cards .card {
        padding: 24px !important;
    }

    .plans-card .card h2 {
        font-size: 40px;
    }

    .payment-flow-section .plan-description {
        margin-bottom: 30px !important;
    }

    .shared-courses-cards .card .card-body h2 {
        font-size: 18px;
    }

    .shared-courses-cards .card-top-img {
        height: 150px;
    }

    .free-trials-cards img {
        height: 130px;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 991px) {
    .welcome-title {
        font-size: 30px;
    }

    .payment-flow-section .plan-description {
        margin-bottom: 30px !important;
    }

    .payment-information-card h2 {
        font-size: 30px;
    }

    .free-trials-cards h3 {
        font-size: 18px;
    }

    .free-trials-cards img {
        height: 140px;
    }
}

@media only screen and (max-width: 991px) and (min-width: 200px) {

    .plans-card .card h3 {
        font-size: 25px;
    }

    .plans-card .card p {
        margin-bottom: 20px;
    }

    .plans-card .card {
        padding: 24px;
    }

    .tailored-mandatory-courses .modal-header {
        padding: 20px 20px 0px 20px;
    }

    .tailored-mandatory-courses .modal-body {
        padding: 20px 20px 0px 20px;
    }

    .tailored-mandatory-courses .modal-footer {
        padding: 20px 20px 20px 20px;
    }

    .tailored-mandatory-courses .modal-title {
        font-size: 18px;
    }

    .cancel-subscription-details label {
        margin-bottom: 20px !important;
    }

    .free-trials-cards h3 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 200px) {
    .welcome-title {
        font-size: 25px;
    }

    .payment-buttons .btn-primary,
    .payment-buttons .btn-explore {
        padding: 15px 15px;
        font-size: 15px;
    }

    .plans-card .card h2 {
        font-size: 40px;
        margin-bottom: 10px;
    }

    .plans-card .card h3 {
        font-size: 25px;
    }

    .plans-card .card p {
        font-size: 16px;
        margin-bottom: 20px;
        height: auto;
    }

    .plans-card .card button {
        margin-top: 40px;
    }

    .available-trails-cards .card-body {
        padding: 0px;
    }

    .available-trails-cards img {
        width: 70px;
    }

    .available-trails-cards .card .card-body h5 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .available-trails-cards .card {
        padding: 15px !important;
    }

    .payment-flow-section .plan-description {
        margin-bottom: 20px !important;
    }

    .available-trails {
        padding-top: 30px;
    }

    .plan-details-list {
        max-width: 100%;
    }

    .payment-flow-section {
        padding-top: 20px;
    }

    .shared-courses-cards .card .card-footer p,
    .shared-courses-cards .card .card-footer h2 {
        font-size: 20px;
    }

    .shared-courses-cards .card .card-body h2 {
        font-size: 20px;
    }

    .shared-courses-cards .card-top-img {
        height: 160px;
    }

    .payment-information-card h2 {
        font-size: 25px;
        margin-bottom: 5px;
    }

    .tailored-mandatory-card h3 {
        font-size: 15px;
    }

    .payment-information-card .card-body span {
        font-size: 16px;
    }

    .payment-information-card .card-body h5 {
        font-size: 14px !important;
    }

    .payment-information-card .card .card-body h4 {
        font-size: 12px;
    }

    .cancel-subscription-details {
        padding: 20px;
    }

    .cancel-subscription .modal-title {
        font-size: 20px;
    }

    .input-radio-box-title,
    .cancel-subscription-description {
        font-size: 14px;
    }

    .cancel-subscription-details button {
        width: 100%;
    }

    .payment-information-card .card-body p {
        font-size: 14px !important;
    }

    .summary-details-list button {
        font-size: 14px;
    }

    .free-trials-cards img {
        height: 100px;
    }

    ._success h2 {
        font-size: 25px;
    }

    .footer-btns button {
        padding: 12px !important;
        font-size: 14px !important;
    }
}

.footer-btns {
    margin-top: 30px;
}


.footer-btns a,
.footer-btns button {
    max-width: 350px;
    width: 100%;
}

.footer-btns a:first-child,
.footer-btns button:first-child {
    border: 1px solid #002FA7;
    border-radius: 8px;
    padding: 12px 24px;
    color: #002FA7;
    font-size: 16px;
    font-weight: 600;
    background-color: #fff;
}

.footer-btns a:first-child:hover,
.footer-btns button:first-child:hover {
    background-color: #002FA7;
    color: #fff;
}

.footer-btns a:last-child,
.footer-btns button:last-child {
    border: 1px solid #DBE5FF;
    border-radius: 8px;
    padding: 12px 24px;
    color: #000C2A;
    font-size: 16px;
    font-weight: 600;
    background-color: #DBE5FF;
    margin-top: 10px;
}

.footer-btns a:last-child:hover,
.footer-btns button:last-child:hover {
    background-color: #fff;
    color: #002FA7;
}

.bulk-upload-details h4 {
    font-size: 24px;
    font-weight: 600;
    color: #000C2A;
    margin-top: 32px;
    margin-bottom: 18px;
}

.bulk-upload-details p {
    font-size: 14px;
    font-weight: 500;
    color: #767577;
    margin-bottom: 0px;
}

.case-studies-card {
    background-image: url("../../public/png/case-studies.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    border-radius: 20px;
    padding: 30px;
}

.case-studies-card h6 {
    margin-bottom: 9px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
}

.case-studies-card h3 {
    margin-bottom: 14px;
    font-size: 28.57px;
    font-weight: 700;
    color: #fff;
}

.case-studies-card p {
    margin-bottom: 14px;
    font-size: 13.33px;
    font-weight: 500;
    color: #ccd1dd;
    max-width: 610px;
    width: 100%;
}

.case-studies-card button {
    font-size: 15.24px;
    font-weight: 600;
    color: #002FA7;
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 11px 22px;
}

.case-studies-card button:hover {
    color: #fff;
    border: 1px solid #002FA7;
    background-color: #002FA7;
}

.top-management-details ul li {
    list-style-type: disc;
    margin-bottom: 8px;
    color: #303030;
    font-size: 12px;
    font-weight: 400;
}

.pie-chart h6 {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5px;
}

.pie-chart p {
    color: #878787;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
}

.pie-chart-box {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    /* background-color: #fff; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url("../../public/png/chart-image.png"); */
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
}

.custom-tooltip ul li {
    list-style-type: disc;
    color: #FBF8FD;
    font-size: 12px;
    font-weight: 400;
    text-align: start;
    margin-bottom: 10px;
}

.compliance-performance-modal .modal-content {
    border-radius: 15px;
}

.compliance-performance-modal .modal-body {
    padding: 30px;
}

.compliance-performance-modal h4 {
    color: #383E49;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 6px;
}

.compliance-performance-modal p {
    color: #8891A0;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
}

.compliance-performance-modal h5 {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}

.compliance-performance-modal h5 span {
    font-weight: 400;
}

.compliance-performance-modal button {
    border: 1px solid #002FA7;
    color: #002FA7;
    padding: 12px 20px;
    border-radius: 8px;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
}

.compliance-performance-modal button:hover {
    color: #fff;
    background-color: #002FA7;
}

@media only screen and (max-width: 1399px) and (min-width: 992px) {
    .footer-btns {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .footer-btns a:last-child,
    .footer-btns button:last-child {
        margin-top: 0px;
    }
}

.page-bg {
    background-color: #DBE5FF;
    padding: 100px 0px;
}

.page-bg h4 {
    font-size: 40px;
    font-weight: 600;
    color: #000C2A;
    text-align: center;
}

.information-details {
    padding: 70px 0px;
}

.information-details h6 {
    font-size: 16px;
    font-weight: 500;
    color: #000C2A;
    margin-bottom: 20px;
}

.information-details h5 {
    font-size: 20px;
    font-weight: 600;
    color: #000C2A;
    margin-bottom: 20px;
}


.information-details ul {
    padding-left: 20px;
    margin: 30px 0px;
}

.information-details ul li {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    color: #686868;
    list-style-type: disc;
}

.speed-meter {
    background-image: url('../../public/png/chart-dots.png');
    background-repeat: no-repeat;
    background-size: 50%;
    background-position-x: center;
    background-position-y: 38%;
}

.chart-details {
    margin-top: -180px;
}

.compliance-details h2 {
    font-size: 64px;
    font-weight: 600;
    color: #242424;
    text-align: center;
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .page-bg h4 {
        font-size: 30px;
    }

    .speed-meter {
        background-image: url('../../public/png/chart-dots.png');
        background-repeat: no-repeat;
        background-size: 40%;
        background-position-x: center;
        background-position-y: 38%;
    }
}

@media only screen and (max-width: 767px) and (min-width: 0px) {
    .page-bg h4 {
        font-size: 25px;
    }

    .page-bg {
        padding: 50px 0px;
    }

    .information-details {
        padding: 30px 0px;
    }


    .information-details h6 {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .information-details h5 {
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .information-details ul li {
        font-size: 14px;
    }

    .chart-details {
        margin-top: 0px;
    }

    .bulk-upload-details h4 {
        font-size: 17px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .bulk-upload-details p {
        margin-bottom: 20px;
    }

    .chart-sm {
        width: 100%;
    }

    .compliance-details h2 {
        font-size: 35px;
    }

    .compliance-details h3 {
        font-size: 16px !important;
    }

    .compliance-details h4 {
        font-size: 16px !important;
    }

    .compliance-details h5 {
        font-size: 16px !important;
    }
}